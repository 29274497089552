import { memo } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';

type Props = {
  children: React.ReactNode
}

export const LoginLayout = memo((props: Props) => {



  return (
    <Container>
      <Row>
        <Nav variant="underline" defaultActiveKey="/home">
          <Nav.Item>
            <Nav.Link href="/home">Home</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link href="/ticket">Ticket</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link href="/user">User</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link href="/team">Team</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link href="/project">Project</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link href="/setting">Setting</Nav.Link>
          </Nav.Item>
        </Nav>
      </Row>
      <Row>
    {props.children}
      </Row>
    </Container>
  );
});