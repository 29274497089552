import { memo, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Stack from 'react-bootstrap/Stack';
import Nav from 'react-bootstrap/Nav';
import Markdown from 'react-markdown'

import { useGlobalConfigContext } from "../providers/GlobalConfig"
import { LoginRequired, AlreadyLogin } from "../providers/LoginUser"
import { LoginLayout } from "../layout/LoginLayout"
import {
  TicketApi,

  GetTicketResponse,
} from "../openapi-generator/admin-api/";

type PageParam = {
  ticket: GetTicketResponse;
}

export const TicketDetailPage = memo(() => {

  const adminApiConfiguration = useGlobalConfigContext().adminApiConfiguration;
  const ticketApi = new TicketApi(adminApiConfiguration);

  const navigate = useNavigate();
  const urlParams = useParams<{ projectId: string, ticketNumber: string }>();
  const [param, setParam] = useState<PageParam>();

  useEffect(()=>{(async()=>{

    const [
      getTicketResponse
    ] = await Promise.all([
      ticketApi.getTicket(urlParams.projectId || "", Number(urlParams.ticketNumber)),
    ]);

    setParam({
      ticket: getTicketResponse.data
    });

  })();},[]);

  if (!!param) {

    return (
      <LoginLayout>
        <Stack direction="horizontal" gap={2}>
          <h2 className="p-2">#{param.ticket.ticketNumber} {param.ticket.title}</h2>
          <div className="p-2"><a href={ `/ticket/${param.ticket.project.projectId}/${param.ticket.ticketNumber}/edit` }>edit</a></div>
        </Stack>

        <dl>
          <dt>project</dt>
          <dd><a href={ `/project/${param.ticket.project.projectId}`}>{param.ticket.project.projectName}</a></dd>

          <dt>mode</dt>
          <dd>{param.ticket.mode}</dd>

          <dt>category</dt>
          <dd>{param.ticket.category.categoryName}</dd>

          <dt>status</dt>
          <dd>{param.ticket.status}</dd>

          <dt>assignedUser</dt>
          <dd>
            { param.ticket.assignedUser
              && <a href={ `/user/${param.ticket.assignedUser.userId}` }>{param.ticket.assignedUser.userName} </a>
            }
            { !param.ticket.assignedUser
              && <>----</>
            }
          </dd>

          <dt>period</dt>
          <dd>{param.ticket.startTime} - {param.ticket.endTime || ""}</dd>

          <dt>body</dt>
          <dd><Markdown>{param.ticket.body}</Markdown></dd>
        </dl>

      </LoginLayout>
    );
  } else {
    return <p>loading...</p>
  }
});