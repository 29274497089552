/* tslint:disable */
/* eslint-disable */
/**
 * Sample API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AddTeamMemberRequest
 */
export interface AddTeamMemberRequest {
    /**
     * 
     * @type {string}
     * @memberof AddTeamMemberRequest
     */
    'userId': string;
}
/**
 * 
 * @export
 * @interface AwsSesTokenForList
 */
export interface AwsSesTokenForList {
    /**
     * 
     * @type {string}
     * @memberof AwsSesTokenForList
     */
    'awsSesTokenId': string;
    /**
     * 
     * @type {TicketModeEnum}
     * @memberof AwsSesTokenForList
     */
    'ticketMode': TicketModeEnum;
    /**
     * 
     * @type {string}
     * @memberof AwsSesTokenForList
     */
    'description'?: string;
}


/**
 * 
 * @export
 * @interface AwsSnsTokenForList
 */
export interface AwsSnsTokenForList {
    /**
     * 
     * @type {string}
     * @memberof AwsSnsTokenForList
     */
    'awsSnsTokenId': string;
    /**
     * 
     * @type {string}
     * @memberof AwsSnsTokenForList
     */
    'endpoint'?: string;
    /**
     * 
     * @type {string}
     * @memberof AwsSnsTokenForList
     */
    'description'?: string;
}
/**
 * 
 * @export
 * @interface BearerTokenForList
 */
export interface BearerTokenForList {
    /**
     * 
     * @type {string}
     * @memberof BearerTokenForList
     */
    'bearerTokenId': string;
    /**
     * 
     * @type {string}
     * @memberof BearerTokenForList
     */
    'description'?: string;
}
/**
 * 
 * @export
 * @interface ContactConfig
 */
export interface ContactConfig {
    /**
     * 
     * @type {ContactConfigMail}
     * @memberof ContactConfig
     */
    'mail'?: ContactConfigMail;
}
/**
 * 
 * @export
 * @interface ContactConfigMail
 */
export interface ContactConfigMail {
    /**
     * 
     * @type {string}
     * @memberof ContactConfigMail
     */
    'level': ContactConfigMailLevelEnum;
    /**
     * 
     * @type {string}
     * @memberof ContactConfigMail
     */
    'mailAddress': string;
}

export const ContactConfigMailLevelEnum = {
    Fatal: 'FATAL',
    Error: 'ERROR',
    Warn: 'WARN',
    Info: 'INFO'
} as const;

export type ContactConfigMailLevelEnum = typeof ContactConfigMailLevelEnum[keyof typeof ContactConfigMailLevelEnum];

/**
 * 
 * @export
 * @interface CreateAwsSesTokenRequest
 */
export interface CreateAwsSesTokenRequest {
    /**
     * 
     * @type {TicketModeEnum}
     * @memberof CreateAwsSesTokenRequest
     */
    'ticketMode': TicketModeEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateAwsSesTokenRequest
     */
    'description'?: string;
}


/**
 * 
 * @export
 * @interface CreateAwsSesTokenResponse
 */
export interface CreateAwsSesTokenResponse {
    /**
     * 
     * @type {string}
     * @memberof CreateAwsSesTokenResponse
     */
    'awsSesTokenId': string;
}
/**
 * 
 * @export
 * @interface CreateAwsSnsTokenRequest
 */
export interface CreateAwsSnsTokenRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateAwsSnsTokenRequest
     */
    'description'?: string;
}
/**
 * 
 * @export
 * @interface CreateAwsSnsTokenResponse
 */
export interface CreateAwsSnsTokenResponse {
    /**
     * 
     * @type {string}
     * @memberof CreateAwsSnsTokenResponse
     */
    'awsSnsTokenId': string;
}
/**
 * 
 * @export
 * @interface CreateBearerTokenRequest
 */
export interface CreateBearerTokenRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateBearerTokenRequest
     */
    'description'?: string;
}
/**
 * 
 * @export
 * @interface CreateBearerTokenResponse
 */
export interface CreateBearerTokenResponse {
    /**
     * 
     * @type {string}
     * @memberof CreateBearerTokenResponse
     */
    'bearerTokenId': string;
}
/**
 * 
 * @export
 * @interface CreateProjectRequest
 */
export interface CreateProjectRequest {
    /**
     * 
     * @type {ProjectForUpdate}
     * @memberof CreateProjectRequest
     */
    'project': ProjectForUpdate;
}
/**
 * 
 * @export
 * @interface CreateProjectResponse
 */
export interface CreateProjectResponse {
    /**
     * 
     * @type {string}
     * @memberof CreateProjectResponse
     */
    'projectId': string;
    /**
     * 
     * @type {number}
     * @memberof CreateProjectResponse
     */
    'version': number;
}
/**
 * 
 * @export
 * @interface CreateTeamRequest
 */
export interface CreateTeamRequest {
    /**
     * 
     * @type {TeamForUpdate}
     * @memberof CreateTeamRequest
     */
    'team': TeamForUpdate;
}
/**
 * 
 * @export
 * @interface CreateTeamResponse
 */
export interface CreateTeamResponse {
    /**
     * 
     * @type {string}
     * @memberof CreateTeamResponse
     */
    'teamId': string;
    /**
     * 
     * @type {number}
     * @memberof CreateTeamResponse
     */
    'version': number;
}
/**
 * 
 * @export
 * @interface CreateTicketCategoryRequest
 */
export interface CreateTicketCategoryRequest {
    /**
     * 
     * @type {TicketCategoryForUpdate}
     * @memberof CreateTicketCategoryRequest
     */
    'ticketCategory': TicketCategoryForUpdate;
}
/**
 * 
 * @export
 * @interface CreateTicketCategoryResponse
 */
export interface CreateTicketCategoryResponse {
    /**
     * 
     * @type {string}
     * @memberof CreateTicketCategoryResponse
     */
    'categoryName'?: string;
    /**
     * 
     * @type {number}
     * @memberof CreateTicketCategoryResponse
     */
    'version': number;
}
/**
 * 
 * @export
 * @interface CreateTicketRequest
 */
export interface CreateTicketRequest {
    /**
     * 
     * @type {TicketForUpdate}
     * @memberof CreateTicketRequest
     */
    'ticket': TicketForUpdate;
}
/**
 * 
 * @export
 * @interface CreateTicketResponse
 */
export interface CreateTicketResponse {
    /**
     * 
     * @type {string}
     * @memberof CreateTicketResponse
     */
    'projectId': string;
    /**
     * 
     * @type {number}
     * @memberof CreateTicketResponse
     */
    'ticketNumber': number;
    /**
     * 
     * @type {number}
     * @memberof CreateTicketResponse
     */
    'version': number;
}
/**
 * 
 * @export
 * @interface DeleteAwsSesTokenRequest
 */
export interface DeleteAwsSesTokenRequest {
    /**
     * 
     * @type {string}
     * @memberof DeleteAwsSesTokenRequest
     */
    'awsSesTokenId': string;
}
/**
 * 
 * @export
 * @interface DeleteAwsSnsTokenRequest
 */
export interface DeleteAwsSnsTokenRequest {
    /**
     * 
     * @type {string}
     * @memberof DeleteAwsSnsTokenRequest
     */
    'awsSnsTokenId': string;
}
/**
 * 
 * @export
 * @interface DeleteBearerTokenRequest
 */
export interface DeleteBearerTokenRequest {
    /**
     * 
     * @type {string}
     * @memberof DeleteBearerTokenRequest
     */
    'bearerTokenId': string;
}
/**
 * 
 * @export
 * @interface DeleteProjectRequest
 */
export interface DeleteProjectRequest {
    /**
     * 
     * @type {number}
     * @memberof DeleteProjectRequest
     */
    'versionExpect': number;
}
/**
 * 
 * @export
 * @interface DeleteTeamRequest
 */
export interface DeleteTeamRequest {
    /**
     * 
     * @type {number}
     * @memberof DeleteTeamRequest
     */
    'versionExpect': number;
}
/**
 * 
 * @export
 * @interface DeleteTicketCategoryRequest
 */
export interface DeleteTicketCategoryRequest {
    /**
     * 
     * @type {number}
     * @memberof DeleteTicketCategoryRequest
     */
    'versionExpect': number;
}
/**
 * 
 * @export
 * @interface DeleteTicketRequest
 */
export interface DeleteTicketRequest {
    /**
     * 
     * @type {number}
     * @memberof DeleteTicketRequest
     */
    'ticketNumber': number;
    /**
     * 
     * @type {number}
     * @memberof DeleteTicketRequest
     */
    'versionExpect': number;
}
/**
 * 
 * @export
 * @interface ErrorResponse
 */
export interface ErrorResponse {
    /**
     * 
     * @type {string}
     * @memberof ErrorResponse
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof ErrorResponse
     */
    'message': string;
}
/**
 * 
 * @export
 * @interface EscalationConfig
 */
export interface EscalationConfig {
    /**
     * 
     * @type {string}
     * @memberof EscalationConfig
     */
    'mode': EscalationConfigModeEnum;
    /**
     * 
     * @type {number}
     * @memberof EscalationConfig
     */
    'waitSeconds': number;
    /**
     * 
     * @type {number}
     * @memberof EscalationConfig
     */
    'tryCount': number;
}

export const EscalationConfigModeEnum = {
    All: 'ALL',
    Random: 'RANDOM'
} as const;

export type EscalationConfigModeEnum = typeof EscalationConfigModeEnum[keyof typeof EscalationConfigModeEnum];

/**
 * 
 * @export
 * @interface GetProjectResponse
 */
export interface GetProjectResponse {
    /**
     * 
     * @type {string}
     * @memberof GetProjectResponse
     */
    'projectId': string;
    /**
     * 
     * @type {string}
     * @memberof GetProjectResponse
     */
    'projectName': string;
    /**
     * 
     * @type {string}
     * @memberof GetProjectResponse
     */
    'description'?: string;
    /**
     * 
     * @type {TeamForField}
     * @memberof GetProjectResponse
     */
    'team': TeamForField;
    /**
     * 
     * @type {number}
     * @memberof GetProjectResponse
     */
    'version': number;
}
/**
 * 
 * @export
 * @interface GetTeamResponse
 */
export interface GetTeamResponse {
    /**
     * 
     * @type {string}
     * @memberof GetTeamResponse
     */
    'teamId': string;
    /**
     * 
     * @type {string}
     * @memberof GetTeamResponse
     */
    'teamName': string;
    /**
     * 
     * @type {string}
     * @memberof GetTeamResponse
     */
    'description'?: string;
    /**
     * 
     * @type {TeamForField}
     * @memberof GetTeamResponse
     */
    'parentTeam'?: TeamForField;
    /**
     * 
     * @type {EscalationConfig}
     * @memberof GetTeamResponse
     */
    'escalationConfig'?: EscalationConfig;
    /**
     * 
     * @type {ContactConfig}
     * @memberof GetTeamResponse
     */
    'contactConfig'?: ContactConfig;
    /**
     * 
     * @type {number}
     * @memberof GetTeamResponse
     */
    'version': number;
}
/**
 * 
 * @export
 * @interface GetTicketCategoryResponse
 */
export interface GetTicketCategoryResponse {
    /**
     * 
     * @type {ProjectForField}
     * @memberof GetTicketCategoryResponse
     */
    'project'?: ProjectForField;
    /**
     * 
     * @type {string}
     * @memberof GetTicketCategoryResponse
     */
    'categoryName': string;
    /**
     * 
     * @type {string}
     * @memberof GetTicketCategoryResponse
     */
    'description'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetTicketCategoryResponse
     */
    'version': number;
}
/**
 * 
 * @export
 * @interface GetTicketResponse
 */
export interface GetTicketResponse {
    /**
     * 
     * @type {ProjectForField}
     * @memberof GetTicketResponse
     */
    'project': ProjectForField;
    /**
     * 
     * @type {number}
     * @memberof GetTicketResponse
     */
    'ticketNumber': number;
    /**
     * 
     * @type {TicketModeEnum}
     * @memberof GetTicketResponse
     */
    'mode': TicketModeEnum;
    /**
     * 
     * @type {TicketCategoryForField}
     * @memberof GetTicketResponse
     */
    'category': TicketCategoryForField;
    /**
     * 
     * @type {TicketStatusEnum}
     * @memberof GetTicketResponse
     */
    'status': TicketStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof GetTicketResponse
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof GetTicketResponse
     */
    'body': string;
    /**
     * 
     * @type {UserForField}
     * @memberof GetTicketResponse
     */
    'assignedUser'?: UserForField;
    /**
     * 
     * @type {string}
     * @memberof GetTicketResponse
     */
    'startTime': string;
    /**
     * 
     * @type {string}
     * @memberof GetTicketResponse
     */
    'endTime'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetTicketResponse
     */
    'version': number;
}


/**
 * 
 * @export
 * @interface GetUserResponse
 */
export interface GetUserResponse {
    /**
     * 
     * @type {string}
     * @memberof GetUserResponse
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof GetUserResponse
     */
    'userName': string;
    /**
     * 
     * @type {ContactConfig}
     * @memberof GetUserResponse
     */
    'contactConfig'?: ContactConfig;
    /**
     * 
     * @type {number}
     * @memberof GetUserResponse
     */
    'version': number;
}
/**
 * 
 * @export
 * @interface GoogleAuthorizationCodeRequest
 */
export interface GoogleAuthorizationCodeRequest {
    /**
     * 
     * @type {string}
     * @memberof GoogleAuthorizationCodeRequest
     */
    'state': string;
    /**
     * 
     * @type {string}
     * @memberof GoogleAuthorizationCodeRequest
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof GoogleAuthorizationCodeRequest
     */
    'scope'?: string;
    /**
     * 
     * @type {number}
     * @memberof GoogleAuthorizationCodeRequest
     */
    'authuser'?: number;
    /**
     * 
     * @type {string}
     * @memberof GoogleAuthorizationCodeRequest
     */
    'prompt'?: string;
    /**
     * 
     * @type {string}
     * @memberof GoogleAuthorizationCodeRequest
     */
    'error'?: string;
}
/**
 * 
 * @export
 * @interface GoogleAuthorizationCodeResponse
 */
export interface GoogleAuthorizationCodeResponse {
    /**
     * 
     * @type {string}
     * @memberof GoogleAuthorizationCodeResponse
     */
    'href': string;
}
/**
 * 
 * @export
 * @interface GoogleAuthorizationUrlResponse
 */
export interface GoogleAuthorizationUrlResponse {
    /**
     * 
     * @type {string}
     * @memberof GoogleAuthorizationUrlResponse
     */
    'href': string;
}
/**
 * 
 * @export
 * @interface ListAwsSesTokenResponse
 */
export interface ListAwsSesTokenResponse {
    /**
     * 
     * @type {Array<AwsSesTokenForList>}
     * @memberof ListAwsSesTokenResponse
     */
    'results': Array<AwsSesTokenForList>;
}
/**
 * 
 * @export
 * @interface ListAwsSnsTokenResponse
 */
export interface ListAwsSnsTokenResponse {
    /**
     * 
     * @type {Array<AwsSnsTokenForList>}
     * @memberof ListAwsSnsTokenResponse
     */
    'results': Array<AwsSnsTokenForList>;
}
/**
 * 
 * @export
 * @interface ListBearerTokenResponse
 */
export interface ListBearerTokenResponse {
    /**
     * 
     * @type {Array<BearerTokenForList>}
     * @memberof ListBearerTokenResponse
     */
    'results': Array<BearerTokenForList>;
}
/**
 * 
 * @export
 * @interface ListProjectResponse
 */
export interface ListProjectResponse {
    /**
     * 
     * @type {number}
     * @memberof ListProjectResponse
     */
    'totalHits': number;
    /**
     * 
     * @type {Array<ProjectForList>}
     * @memberof ListProjectResponse
     */
    'results': Array<ProjectForList>;
}
/**
 * 
 * @export
 * @interface ListTeamMemberResponse
 */
export interface ListTeamMemberResponse {
    /**
     * 
     * @type {number}
     * @memberof ListTeamMemberResponse
     */
    'totalHits': number;
    /**
     * 
     * @type {Array<TeamMemberForList>}
     * @memberof ListTeamMemberResponse
     */
    'results': Array<TeamMemberForList>;
}
/**
 * 
 * @export
 * @interface ListTeamResponse
 */
export interface ListTeamResponse {
    /**
     * 
     * @type {number}
     * @memberof ListTeamResponse
     */
    'totalHits': number;
    /**
     * 
     * @type {Array<TeamForList>}
     * @memberof ListTeamResponse
     */
    'results': Array<TeamForList>;
}
/**
 * 
 * @export
 * @interface ListTicketCategoryResponse
 */
export interface ListTicketCategoryResponse {
    /**
     * 
     * @type {number}
     * @memberof ListTicketCategoryResponse
     */
    'totalHits': number;
    /**
     * 
     * @type {Array<TicketCategoryForList>}
     * @memberof ListTicketCategoryResponse
     */
    'results': Array<TicketCategoryForList>;
}
/**
 * 
 * @export
 * @interface ListTicketResponse
 */
export interface ListTicketResponse {
    /**
     * 
     * @type {number}
     * @memberof ListTicketResponse
     */
    'totalHits': number;
    /**
     * 
     * @type {Array<TicketForList>}
     * @memberof ListTicketResponse
     */
    'results': Array<TicketForList>;
}
/**
 * 
 * @export
 * @interface ListUserResponse
 */
export interface ListUserResponse {
    /**
     * 
     * @type {number}
     * @memberof ListUserResponse
     */
    'totalHits': number;
    /**
     * 
     * @type {Array<UserForList>}
     * @memberof ListUserResponse
     */
    'results': Array<UserForList>;
}
/**
 * 
 * @export
 * @interface MyInfo
 */
export interface MyInfo {
    /**
     * 
     * @type {string}
     * @memberof MyInfo
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof MyInfo
     */
    'userName': string;
}
/**
 * 
 * @export
 * @interface ProjectForField
 */
export interface ProjectForField {
    /**
     * 
     * @type {string}
     * @memberof ProjectForField
     */
    'projectId': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectForField
     */
    'projectName': string;
    /**
     * 
     * @type {number}
     * @memberof ProjectForField
     */
    'version': number;
}
/**
 * 
 * @export
 * @interface ProjectForList
 */
export interface ProjectForList {
    /**
     * 
     * @type {string}
     * @memberof ProjectForList
     */
    'projectId': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectForList
     */
    'projectName': string;
    /**
     * 
     * @type {TeamForField}
     * @memberof ProjectForList
     */
    'team': TeamForField;
    /**
     * 
     * @type {number}
     * @memberof ProjectForList
     */
    'version': number;
}
/**
 * 
 * @export
 * @interface ProjectForUpdate
 */
export interface ProjectForUpdate {
    /**
     * 
     * @type {string}
     * @memberof ProjectForUpdate
     */
    'projectName': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectForUpdate
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectForUpdate
     */
    'teamId': string;
}
/**
 * 
 * @export
 * @interface RemoveTeamMemberRequest
 */
export interface RemoveTeamMemberRequest {
    /**
     * 
     * @type {string}
     * @memberof RemoveTeamMemberRequest
     */
    'userId': string;
}
/**
 * 
 * @export
 * @interface TeamForField
 */
export interface TeamForField {
    /**
     * 
     * @type {string}
     * @memberof TeamForField
     */
    'teamId': string;
    /**
     * 
     * @type {string}
     * @memberof TeamForField
     */
    'teamName': string;
    /**
     * 
     * @type {number}
     * @memberof TeamForField
     */
    'version'?: number;
}
/**
 * 
 * @export
 * @interface TeamForList
 */
export interface TeamForList {
    /**
     * 
     * @type {string}
     * @memberof TeamForList
     */
    'teamId': string;
    /**
     * 
     * @type {string}
     * @memberof TeamForList
     */
    'teamName': string;
    /**
     * 
     * @type {number}
     * @memberof TeamForList
     */
    'version'?: number;
}
/**
 * 
 * @export
 * @interface TeamForUpdate
 */
export interface TeamForUpdate {
    /**
     * 
     * @type {string}
     * @memberof TeamForUpdate
     */
    'teamName': string;
    /**
     * 
     * @type {string}
     * @memberof TeamForUpdate
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof TeamForUpdate
     */
    'parentTeamId'?: string;
    /**
     * 
     * @type {EscalationConfig}
     * @memberof TeamForUpdate
     */
    'escalationConfig': EscalationConfig;
    /**
     * 
     * @type {ContactConfig}
     * @memberof TeamForUpdate
     */
    'contactConfig'?: ContactConfig;
}
/**
 * 
 * @export
 * @interface TeamMemberForList
 */
export interface TeamMemberForList {
    /**
     * 
     * @type {UserForField}
     * @memberof TeamMemberForList
     */
    'user': UserForField;
    /**
     * 
     * @type {string}
     * @memberof TeamMemberForList
     */
    'description'?: string;
    /**
     * 
     * @type {number}
     * @memberof TeamMemberForList
     */
    'version': number;
}
/**
 * 
 * @export
 * @interface TicketCategoryForField
 */
export interface TicketCategoryForField {
    /**
     * 
     * @type {string}
     * @memberof TicketCategoryForField
     */
    'categoryName': string;
    /**
     * 
     * @type {number}
     * @memberof TicketCategoryForField
     */
    'version': number;
}
/**
 * 
 * @export
 * @interface TicketCategoryForList
 */
export interface TicketCategoryForList {
    /**
     * 
     * @type {ProjectForField}
     * @memberof TicketCategoryForList
     */
    'project': ProjectForField;
    /**
     * 
     * @type {string}
     * @memberof TicketCategoryForList
     */
    'categoryName': string;
    /**
     * 
     * @type {number}
     * @memberof TicketCategoryForList
     */
    'version': number;
}
/**
 * aaa
 * @export
 * @interface TicketCategoryForUpdate
 */
export interface TicketCategoryForUpdate {
    /**
     * 
     * @type {string}
     * @memberof TicketCategoryForUpdate
     */
    'description'?: string;
}
/**
 * 
 * @export
 * @interface TicketForList
 */
export interface TicketForList {
    /**
     * 
     * @type {number}
     * @memberof TicketForList
     */
    'ticketNumber'?: number;
    /**
     * 
     * @type {ProjectForField}
     * @memberof TicketForList
     */
    'project': ProjectForField;
    /**
     * 
     * @type {TicketModeEnum}
     * @memberof TicketForList
     */
    'mode': TicketModeEnum;
    /**
     * 
     * @type {TicketCategoryForField}
     * @memberof TicketForList
     */
    'category'?: TicketCategoryForField;
    /**
     * 
     * @type {TicketStatusEnum}
     * @memberof TicketForList
     */
    'status': TicketStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof TicketForList
     */
    'title': string;
    /**
     * 
     * @type {UserForField}
     * @memberof TicketForList
     */
    'assignedUser'?: UserForField;
    /**
     * 
     * @type {string}
     * @memberof TicketForList
     */
    'startTime': string;
    /**
     * 
     * @type {string}
     * @memberof TicketForList
     */
    'endTime'?: string;
    /**
     * 
     * @type {number}
     * @memberof TicketForList
     */
    'version': number;
}


/**
 * 
 * @export
 * @interface TicketForUpdate
 */
export interface TicketForUpdate {
    /**
     * 
     * @type {TicketModeEnum}
     * @memberof TicketForUpdate
     */
    'mode': TicketModeEnum;
    /**
     * 
     * @type {string}
     * @memberof TicketForUpdate
     */
    'categoryName': string;
    /**
     * 
     * @type {TicketStatusEnum}
     * @memberof TicketForUpdate
     */
    'status': TicketStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof TicketForUpdate
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof TicketForUpdate
     */
    'body': string;
    /**
     * 
     * @type {string}
     * @memberof TicketForUpdate
     */
    'assignedUserId'?: string;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const TicketModeEnum = {
    Incident: 'INCIDENT',
    Maintenance: 'MAINTENANCE',
    Task: 'TASK'
} as const;

export type TicketModeEnum = typeof TicketModeEnum[keyof typeof TicketModeEnum];


/**
 * 
 * @export
 * @enum {string}
 */

export const TicketStatusEnum = {
    Open: 'OPEN',
    Close: 'CLOSE'
} as const;

export type TicketStatusEnum = typeof TicketStatusEnum[keyof typeof TicketStatusEnum];


/**
 * 
 * @export
 * @interface UpdateProjectRequest
 */
export interface UpdateProjectRequest {
    /**
     * 
     * @type {ProjectForUpdate}
     * @memberof UpdateProjectRequest
     */
    'project': ProjectForUpdate;
    /**
     * 
     * @type {number}
     * @memberof UpdateProjectRequest
     */
    'versionExpect': number;
}
/**
 * 
 * @export
 * @interface UpdateProjectResponse
 */
export interface UpdateProjectResponse {
    /**
     * 
     * @type {string}
     * @memberof UpdateProjectResponse
     */
    'projectId': string;
    /**
     * 
     * @type {number}
     * @memberof UpdateProjectResponse
     */
    'version': number;
}
/**
 * 
 * @export
 * @interface UpdateTeamRequest
 */
export interface UpdateTeamRequest {
    /**
     * 
     * @type {TeamForUpdate}
     * @memberof UpdateTeamRequest
     */
    'team': TeamForUpdate;
    /**
     * 
     * @type {number}
     * @memberof UpdateTeamRequest
     */
    'versionExpect': number;
}
/**
 * 
 * @export
 * @interface UpdateTeamResponse
 */
export interface UpdateTeamResponse {
    /**
     * 
     * @type {string}
     * @memberof UpdateTeamResponse
     */
    'teamId': string;
    /**
     * 
     * @type {number}
     * @memberof UpdateTeamResponse
     */
    'version': number;
}
/**
 * 
 * @export
 * @interface UpdateTicketCategoryRequest
 */
export interface UpdateTicketCategoryRequest {
    /**
     * 
     * @type {TicketCategoryForUpdate}
     * @memberof UpdateTicketCategoryRequest
     */
    'ticketCategory': TicketCategoryForUpdate;
    /**
     * 
     * @type {number}
     * @memberof UpdateTicketCategoryRequest
     */
    'versionExpect': number;
}
/**
 * 
 * @export
 * @interface UpdateTicketCategoryResponse
 */
export interface UpdateTicketCategoryResponse {
    /**
     * 
     * @type {string}
     * @memberof UpdateTicketCategoryResponse
     */
    'categoryName': string;
    /**
     * 
     * @type {number}
     * @memberof UpdateTicketCategoryResponse
     */
    'version': number;
}
/**
 * 
 * @export
 * @interface UpdateTicketRequest
 */
export interface UpdateTicketRequest {
    /**
     * 
     * @type {number}
     * @memberof UpdateTicketRequest
     */
    'ticketNumber': number;
    /**
     * 
     * @type {TicketForUpdate}
     * @memberof UpdateTicketRequest
     */
    'ticket': TicketForUpdate;
    /**
     * 
     * @type {number}
     * @memberof UpdateTicketRequest
     */
    'versionExpect': number;
}
/**
 * 
 * @export
 * @interface UpdateTicketResponse
 */
export interface UpdateTicketResponse {
    /**
     * 
     * @type {string}
     * @memberof UpdateTicketResponse
     */
    'projectId': string;
    /**
     * 
     * @type {number}
     * @memberof UpdateTicketResponse
     */
    'ticketNumber': number;
    /**
     * 
     * @type {number}
     * @memberof UpdateTicketResponse
     */
    'version': number;
}
/**
 * aaa
 * @export
 * @interface UpdateUserRequest
 */
export interface UpdateUserRequest {
    /**
     * 
     * @type {UserForUpdate}
     * @memberof UpdateUserRequest
     */
    'user': UserForUpdate;
    /**
     * 
     * @type {number}
     * @memberof UpdateUserRequest
     */
    'versionExpect': number;
}
/**
 * 
 * @export
 * @interface UpdateUserResponse
 */
export interface UpdateUserResponse {
    /**
     * 
     * @type {string}
     * @memberof UpdateUserResponse
     */
    'userId': string;
    /**
     * 
     * @type {number}
     * @memberof UpdateUserResponse
     */
    'version': number;
}
/**
 * 
 * @export
 * @interface UserForField
 */
export interface UserForField {
    /**
     * 
     * @type {string}
     * @memberof UserForField
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof UserForField
     */
    'userName': string;
    /**
     * 
     * @type {number}
     * @memberof UserForField
     */
    'version': number;
}
/**
 * 
 * @export
 * @interface UserForList
 */
export interface UserForList {
    /**
     * 
     * @type {string}
     * @memberof UserForList
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof UserForList
     */
    'userName': string;
    /**
     * 
     * @type {number}
     * @memberof UserForList
     */
    'version': number;
}
/**
 * 
 * @export
 * @interface UserForUpdate
 */
export interface UserForUpdate {
    /**
     * 
     * @type {string}
     * @memberof UserForUpdate
     */
    'userName': string;
    /**
     * 
     * @type {ContactConfig}
     * @memberof UserForUpdate
     */
    'contactConfig'?: ContactConfig;
}
/**
 * 
 * @export
 * @interface WhoAmIResponse
 */
export interface WhoAmIResponse {
    /**
     * 
     * @type {MyInfo}
     * @memberof WhoAmIResponse
     */
    'myInfo'?: MyInfo;
}

/**
 * AwsSnsIntegrationApi - axios parameter creator
 * @export
 */
export const AwsSnsIntegrationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} token 
         * @param {File} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        awsSnsIntegration: async (token: string, body: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            assertParamExists('awsSnsIntegration', 'token', token)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('awsSnsIntegration', 'body', body)
            const localVarPath = `/integration/aws-sns/{token}`
                .replace(`{${"token"}}`, encodeURIComponent(String(token)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'text/plain';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AwsSnsIntegrationApi - functional programming interface
 * @export
 */
export const AwsSnsIntegrationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AwsSnsIntegrationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} token 
         * @param {File} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async awsSnsIntegration(token: string, body: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.awsSnsIntegration(token, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AwsSnsIntegrationApi - factory interface
 * @export
 */
export const AwsSnsIntegrationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AwsSnsIntegrationApiFp(configuration)
    return {
        /**
         * 
         * @param {string} token 
         * @param {File} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        awsSnsIntegration(token: string, body: File, options?: any): AxiosPromise<string> {
            return localVarFp.awsSnsIntegration(token, body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AwsSnsIntegrationApi - object-oriented interface
 * @export
 * @class AwsSnsIntegrationApi
 * @extends {BaseAPI}
 */
export class AwsSnsIntegrationApi extends BaseAPI {
    /**
     * 
     * @param {string} token 
     * @param {File} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AwsSnsIntegrationApi
     */
    public awsSnsIntegration(token: string, body: File, options?: AxiosRequestConfig) {
        return AwsSnsIntegrationApiFp(this.configuration).awsSnsIntegration(token, body, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * BearerTokenApi - axios parameter creator
 * @export
 */
export const BearerTokenApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateBearerTokenRequest} createBearerTokenRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBearerToken: async (createBearerTokenRequest: CreateBearerTokenRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createBearerTokenRequest' is not null or undefined
            assertParamExists('createBearerToken', 'createBearerTokenRequest', createBearerTokenRequest)
            const localVarPath = `/api/bearer-token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createBearerTokenRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DeleteBearerTokenRequest} deleteBearerTokenRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBearerToken: async (deleteBearerTokenRequest: DeleteBearerTokenRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deleteBearerTokenRequest' is not null or undefined
            assertParamExists('deleteBearerToken', 'deleteBearerTokenRequest', deleteBearerTokenRequest)
            const localVarPath = `/api/bearer-token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteBearerTokenRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listBearerToken: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/bearer-token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BearerTokenApi - functional programming interface
 * @export
 */
export const BearerTokenApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BearerTokenApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateBearerTokenRequest} createBearerTokenRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createBearerToken(createBearerTokenRequest: CreateBearerTokenRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateBearerTokenResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createBearerToken(createBearerTokenRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DeleteBearerTokenRequest} deleteBearerTokenRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteBearerToken(deleteBearerTokenRequest: DeleteBearerTokenRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteBearerToken(deleteBearerTokenRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listBearerToken(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListBearerTokenResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listBearerToken(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BearerTokenApi - factory interface
 * @export
 */
export const BearerTokenApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BearerTokenApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateBearerTokenRequest} createBearerTokenRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBearerToken(createBearerTokenRequest: CreateBearerTokenRequest, options?: any): AxiosPromise<CreateBearerTokenResponse> {
            return localVarFp.createBearerToken(createBearerTokenRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DeleteBearerTokenRequest} deleteBearerTokenRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBearerToken(deleteBearerTokenRequest: DeleteBearerTokenRequest, options?: any): AxiosPromise<void> {
            return localVarFp.deleteBearerToken(deleteBearerTokenRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listBearerToken(options?: any): AxiosPromise<ListBearerTokenResponse> {
            return localVarFp.listBearerToken(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BearerTokenApi - object-oriented interface
 * @export
 * @class BearerTokenApi
 * @extends {BaseAPI}
 */
export class BearerTokenApi extends BaseAPI {
    /**
     * 
     * @param {CreateBearerTokenRequest} createBearerTokenRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BearerTokenApi
     */
    public createBearerToken(createBearerTokenRequest: CreateBearerTokenRequest, options?: AxiosRequestConfig) {
        return BearerTokenApiFp(this.configuration).createBearerToken(createBearerTokenRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DeleteBearerTokenRequest} deleteBearerTokenRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BearerTokenApi
     */
    public deleteBearerToken(deleteBearerTokenRequest: DeleteBearerTokenRequest, options?: AxiosRequestConfig) {
        return BearerTokenApiFp(this.configuration).deleteBearerToken(deleteBearerTokenRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BearerTokenApi
     */
    public listBearerToken(options?: AxiosRequestConfig) {
        return BearerTokenApiFp(this.configuration).listBearerToken(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * GoogleAuthorizationApi - axios parameter creator
 * @export
 */
export const GoogleAuthorizationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {GoogleAuthorizationCodeRequest} googleAuthorizationCodeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        googleAuthorizationCode: async (googleAuthorizationCodeRequest: GoogleAuthorizationCodeRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'googleAuthorizationCodeRequest' is not null or undefined
            assertParamExists('googleAuthorizationCode', 'googleAuthorizationCodeRequest', googleAuthorizationCodeRequest)
            const localVarPath = `/api/google-login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(googleAuthorizationCodeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [backUrl] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        googleAuthorizationUrl: async (backUrl?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/google-login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (backUrl !== undefined) {
                localVarQueryParameter['back_url'] = backUrl;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GoogleAuthorizationApi - functional programming interface
 * @export
 */
export const GoogleAuthorizationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GoogleAuthorizationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {GoogleAuthorizationCodeRequest} googleAuthorizationCodeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async googleAuthorizationCode(googleAuthorizationCodeRequest: GoogleAuthorizationCodeRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GoogleAuthorizationCodeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.googleAuthorizationCode(googleAuthorizationCodeRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [backUrl] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async googleAuthorizationUrl(backUrl?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GoogleAuthorizationUrlResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.googleAuthorizationUrl(backUrl, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GoogleAuthorizationApi - factory interface
 * @export
 */
export const GoogleAuthorizationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GoogleAuthorizationApiFp(configuration)
    return {
        /**
         * 
         * @param {GoogleAuthorizationCodeRequest} googleAuthorizationCodeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        googleAuthorizationCode(googleAuthorizationCodeRequest: GoogleAuthorizationCodeRequest, options?: any): AxiosPromise<GoogleAuthorizationCodeResponse> {
            return localVarFp.googleAuthorizationCode(googleAuthorizationCodeRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [backUrl] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        googleAuthorizationUrl(backUrl?: string, options?: any): AxiosPromise<GoogleAuthorizationUrlResponse> {
            return localVarFp.googleAuthorizationUrl(backUrl, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GoogleAuthorizationApi - object-oriented interface
 * @export
 * @class GoogleAuthorizationApi
 * @extends {BaseAPI}
 */
export class GoogleAuthorizationApi extends BaseAPI {
    /**
     * 
     * @param {GoogleAuthorizationCodeRequest} googleAuthorizationCodeRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GoogleAuthorizationApi
     */
    public googleAuthorizationCode(googleAuthorizationCodeRequest: GoogleAuthorizationCodeRequest, options?: AxiosRequestConfig) {
        return GoogleAuthorizationApiFp(this.configuration).googleAuthorizationCode(googleAuthorizationCodeRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [backUrl] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GoogleAuthorizationApi
     */
    public googleAuthorizationUrl(backUrl?: string, options?: AxiosRequestConfig) {
        return GoogleAuthorizationApiFp(this.configuration).googleAuthorizationUrl(backUrl, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProjectApi - axios parameter creator
 * @export
 */
export const ProjectApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} projectId 
         * @param {CreateProjectRequest} createProjectRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProject: async (projectId: string, createProjectRequest: CreateProjectRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('createProject', 'projectId', projectId)
            // verify required parameter 'createProjectRequest' is not null or undefined
            assertParamExists('createProject', 'createProjectRequest', createProjectRequest)
            const localVarPath = `/api/project/{projectId}`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createProjectRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {DeleteProjectRequest} deleteProjectRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProject: async (projectId: string, deleteProjectRequest: DeleteProjectRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('deleteProject', 'projectId', projectId)
            // verify required parameter 'deleteProjectRequest' is not null or undefined
            assertParamExists('deleteProject', 'deleteProjectRequest', deleteProjectRequest)
            const localVarPath = `/api/project/{projectId}`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteProjectRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProject: async (projectId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getProject', 'projectId', projectId)
            const localVarPath = `/api/project/{projectId}`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [from] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listProject: async (from?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/project`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {UpdateProjectRequest} updateProjectRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProject: async (projectId: string, updateProjectRequest: UpdateProjectRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('updateProject', 'projectId', projectId)
            // verify required parameter 'updateProjectRequest' is not null or undefined
            assertParamExists('updateProject', 'updateProjectRequest', updateProjectRequest)
            const localVarPath = `/api/project/{projectId}`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateProjectRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProjectApi - functional programming interface
 * @export
 */
export const ProjectApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProjectApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} projectId 
         * @param {CreateProjectRequest} createProjectRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createProject(projectId: string, createProjectRequest: CreateProjectRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateProjectResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createProject(projectId, createProjectRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} projectId 
         * @param {DeleteProjectRequest} deleteProjectRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteProject(projectId: string, deleteProjectRequest: DeleteProjectRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteProject(projectId, deleteProjectRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProject(projectId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetProjectResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProject(projectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [from] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listProject(from?: number, size?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListProjectResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listProject(from, size, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} projectId 
         * @param {UpdateProjectRequest} updateProjectRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateProject(projectId: string, updateProjectRequest: UpdateProjectRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateProjectResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateProject(projectId, updateProjectRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProjectApi - factory interface
 * @export
 */
export const ProjectApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProjectApiFp(configuration)
    return {
        /**
         * 
         * @param {string} projectId 
         * @param {CreateProjectRequest} createProjectRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProject(projectId: string, createProjectRequest: CreateProjectRequest, options?: any): AxiosPromise<CreateProjectResponse> {
            return localVarFp.createProject(projectId, createProjectRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {DeleteProjectRequest} deleteProjectRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProject(projectId: string, deleteProjectRequest: DeleteProjectRequest, options?: any): AxiosPromise<void> {
            return localVarFp.deleteProject(projectId, deleteProjectRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProject(projectId: string, options?: any): AxiosPromise<GetProjectResponse> {
            return localVarFp.getProject(projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [from] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listProject(from?: number, size?: number, options?: any): AxiosPromise<ListProjectResponse> {
            return localVarFp.listProject(from, size, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {UpdateProjectRequest} updateProjectRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProject(projectId: string, updateProjectRequest: UpdateProjectRequest, options?: any): AxiosPromise<UpdateProjectResponse> {
            return localVarFp.updateProject(projectId, updateProjectRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProjectApi - object-oriented interface
 * @export
 * @class ProjectApi
 * @extends {BaseAPI}
 */
export class ProjectApi extends BaseAPI {
    /**
     * 
     * @param {string} projectId 
     * @param {CreateProjectRequest} createProjectRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public createProject(projectId: string, createProjectRequest: CreateProjectRequest, options?: AxiosRequestConfig) {
        return ProjectApiFp(this.configuration).createProject(projectId, createProjectRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} projectId 
     * @param {DeleteProjectRequest} deleteProjectRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public deleteProject(projectId: string, deleteProjectRequest: DeleteProjectRequest, options?: AxiosRequestConfig) {
        return ProjectApiFp(this.configuration).deleteProject(projectId, deleteProjectRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} projectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public getProject(projectId: string, options?: AxiosRequestConfig) {
        return ProjectApiFp(this.configuration).getProject(projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [from] 
     * @param {number} [size] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public listProject(from?: number, size?: number, options?: AxiosRequestConfig) {
        return ProjectApiFp(this.configuration).listProject(from, size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} projectId 
     * @param {UpdateProjectRequest} updateProjectRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public updateProject(projectId: string, updateProjectRequest: UpdateProjectRequest, options?: AxiosRequestConfig) {
        return ProjectApiFp(this.configuration).updateProject(projectId, updateProjectRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProjectAwsSesTokenApi - axios parameter creator
 * @export
 */
export const ProjectAwsSesTokenApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} projectId 
         * @param {CreateAwsSesTokenRequest} createAwsSesTokenRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAwsSesToken: async (projectId: string, createAwsSesTokenRequest: CreateAwsSesTokenRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('createAwsSesToken', 'projectId', projectId)
            // verify required parameter 'createAwsSesTokenRequest' is not null or undefined
            assertParamExists('createAwsSesToken', 'createAwsSesTokenRequest', createAwsSesTokenRequest)
            const localVarPath = `/api/project/{projectId}/token/aws-ses`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createAwsSesTokenRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {DeleteAwsSesTokenRequest} deleteAwsSesTokenRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAwsSesToken: async (projectId: string, deleteAwsSesTokenRequest: DeleteAwsSesTokenRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('deleteAwsSesToken', 'projectId', projectId)
            // verify required parameter 'deleteAwsSesTokenRequest' is not null or undefined
            assertParamExists('deleteAwsSesToken', 'deleteAwsSesTokenRequest', deleteAwsSesTokenRequest)
            const localVarPath = `/api/project/{projectId}/token/aws-ses`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteAwsSesTokenRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAwsSesToken: async (projectId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('listAwsSesToken', 'projectId', projectId)
            const localVarPath = `/api/project/{projectId}/token/aws-ses`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProjectAwsSesTokenApi - functional programming interface
 * @export
 */
export const ProjectAwsSesTokenApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProjectAwsSesTokenApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} projectId 
         * @param {CreateAwsSesTokenRequest} createAwsSesTokenRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAwsSesToken(projectId: string, createAwsSesTokenRequest: CreateAwsSesTokenRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateAwsSesTokenResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAwsSesToken(projectId, createAwsSesTokenRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} projectId 
         * @param {DeleteAwsSesTokenRequest} deleteAwsSesTokenRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAwsSesToken(projectId: string, deleteAwsSesTokenRequest: DeleteAwsSesTokenRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAwsSesToken(projectId, deleteAwsSesTokenRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listAwsSesToken(projectId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListAwsSesTokenResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listAwsSesToken(projectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProjectAwsSesTokenApi - factory interface
 * @export
 */
export const ProjectAwsSesTokenApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProjectAwsSesTokenApiFp(configuration)
    return {
        /**
         * 
         * @param {string} projectId 
         * @param {CreateAwsSesTokenRequest} createAwsSesTokenRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAwsSesToken(projectId: string, createAwsSesTokenRequest: CreateAwsSesTokenRequest, options?: any): AxiosPromise<CreateAwsSesTokenResponse> {
            return localVarFp.createAwsSesToken(projectId, createAwsSesTokenRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {DeleteAwsSesTokenRequest} deleteAwsSesTokenRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAwsSesToken(projectId: string, deleteAwsSesTokenRequest: DeleteAwsSesTokenRequest, options?: any): AxiosPromise<void> {
            return localVarFp.deleteAwsSesToken(projectId, deleteAwsSesTokenRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAwsSesToken(projectId: string, options?: any): AxiosPromise<ListAwsSesTokenResponse> {
            return localVarFp.listAwsSesToken(projectId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProjectAwsSesTokenApi - object-oriented interface
 * @export
 * @class ProjectAwsSesTokenApi
 * @extends {BaseAPI}
 */
export class ProjectAwsSesTokenApi extends BaseAPI {
    /**
     * 
     * @param {string} projectId 
     * @param {CreateAwsSesTokenRequest} createAwsSesTokenRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectAwsSesTokenApi
     */
    public createAwsSesToken(projectId: string, createAwsSesTokenRequest: CreateAwsSesTokenRequest, options?: AxiosRequestConfig) {
        return ProjectAwsSesTokenApiFp(this.configuration).createAwsSesToken(projectId, createAwsSesTokenRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} projectId 
     * @param {DeleteAwsSesTokenRequest} deleteAwsSesTokenRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectAwsSesTokenApi
     */
    public deleteAwsSesToken(projectId: string, deleteAwsSesTokenRequest: DeleteAwsSesTokenRequest, options?: AxiosRequestConfig) {
        return ProjectAwsSesTokenApiFp(this.configuration).deleteAwsSesToken(projectId, deleteAwsSesTokenRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} projectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectAwsSesTokenApi
     */
    public listAwsSesToken(projectId: string, options?: AxiosRequestConfig) {
        return ProjectAwsSesTokenApiFp(this.configuration).listAwsSesToken(projectId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProjectAwsSnsTokenApi - axios parameter creator
 * @export
 */
export const ProjectAwsSnsTokenApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} projectId 
         * @param {CreateAwsSnsTokenRequest} createAwsSnsTokenRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAwsSnsToken: async (projectId: string, createAwsSnsTokenRequest: CreateAwsSnsTokenRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('createAwsSnsToken', 'projectId', projectId)
            // verify required parameter 'createAwsSnsTokenRequest' is not null or undefined
            assertParamExists('createAwsSnsToken', 'createAwsSnsTokenRequest', createAwsSnsTokenRequest)
            const localVarPath = `/api/project/{projectId}/token/aws-sns`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createAwsSnsTokenRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {DeleteAwsSnsTokenRequest} deleteAwsSnsTokenRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAwsSnsToken: async (projectId: string, deleteAwsSnsTokenRequest: DeleteAwsSnsTokenRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('deleteAwsSnsToken', 'projectId', projectId)
            // verify required parameter 'deleteAwsSnsTokenRequest' is not null or undefined
            assertParamExists('deleteAwsSnsToken', 'deleteAwsSnsTokenRequest', deleteAwsSnsTokenRequest)
            const localVarPath = `/api/project/{projectId}/token/aws-sns`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteAwsSnsTokenRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAwsSnsToken: async (projectId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('listAwsSnsToken', 'projectId', projectId)
            const localVarPath = `/api/project/{projectId}/token/aws-sns`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProjectAwsSnsTokenApi - functional programming interface
 * @export
 */
export const ProjectAwsSnsTokenApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProjectAwsSnsTokenApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} projectId 
         * @param {CreateAwsSnsTokenRequest} createAwsSnsTokenRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAwsSnsToken(projectId: string, createAwsSnsTokenRequest: CreateAwsSnsTokenRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateAwsSnsTokenResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAwsSnsToken(projectId, createAwsSnsTokenRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} projectId 
         * @param {DeleteAwsSnsTokenRequest} deleteAwsSnsTokenRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAwsSnsToken(projectId: string, deleteAwsSnsTokenRequest: DeleteAwsSnsTokenRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAwsSnsToken(projectId, deleteAwsSnsTokenRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listAwsSnsToken(projectId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListAwsSnsTokenResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listAwsSnsToken(projectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProjectAwsSnsTokenApi - factory interface
 * @export
 */
export const ProjectAwsSnsTokenApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProjectAwsSnsTokenApiFp(configuration)
    return {
        /**
         * 
         * @param {string} projectId 
         * @param {CreateAwsSnsTokenRequest} createAwsSnsTokenRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAwsSnsToken(projectId: string, createAwsSnsTokenRequest: CreateAwsSnsTokenRequest, options?: any): AxiosPromise<CreateAwsSnsTokenResponse> {
            return localVarFp.createAwsSnsToken(projectId, createAwsSnsTokenRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {DeleteAwsSnsTokenRequest} deleteAwsSnsTokenRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAwsSnsToken(projectId: string, deleteAwsSnsTokenRequest: DeleteAwsSnsTokenRequest, options?: any): AxiosPromise<void> {
            return localVarFp.deleteAwsSnsToken(projectId, deleteAwsSnsTokenRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAwsSnsToken(projectId: string, options?: any): AxiosPromise<ListAwsSnsTokenResponse> {
            return localVarFp.listAwsSnsToken(projectId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProjectAwsSnsTokenApi - object-oriented interface
 * @export
 * @class ProjectAwsSnsTokenApi
 * @extends {BaseAPI}
 */
export class ProjectAwsSnsTokenApi extends BaseAPI {
    /**
     * 
     * @param {string} projectId 
     * @param {CreateAwsSnsTokenRequest} createAwsSnsTokenRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectAwsSnsTokenApi
     */
    public createAwsSnsToken(projectId: string, createAwsSnsTokenRequest: CreateAwsSnsTokenRequest, options?: AxiosRequestConfig) {
        return ProjectAwsSnsTokenApiFp(this.configuration).createAwsSnsToken(projectId, createAwsSnsTokenRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} projectId 
     * @param {DeleteAwsSnsTokenRequest} deleteAwsSnsTokenRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectAwsSnsTokenApi
     */
    public deleteAwsSnsToken(projectId: string, deleteAwsSnsTokenRequest: DeleteAwsSnsTokenRequest, options?: AxiosRequestConfig) {
        return ProjectAwsSnsTokenApiFp(this.configuration).deleteAwsSnsToken(projectId, deleteAwsSnsTokenRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} projectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectAwsSnsTokenApi
     */
    public listAwsSnsToken(projectId: string, options?: AxiosRequestConfig) {
        return ProjectAwsSnsTokenApiFp(this.configuration).listAwsSnsToken(projectId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TeamApi - axios parameter creator
 * @export
 */
export const TeamApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} teamId 
         * @param {CreateTeamRequest} createTeamRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTeam: async (teamId: string, createTeamRequest: CreateTeamRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'teamId' is not null or undefined
            assertParamExists('createTeam', 'teamId', teamId)
            // verify required parameter 'createTeamRequest' is not null or undefined
            assertParamExists('createTeam', 'createTeamRequest', createTeamRequest)
            const localVarPath = `/api/team/{teamId}`
                .replace(`{${"teamId"}}`, encodeURIComponent(String(teamId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createTeamRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} teamId 
         * @param {DeleteTeamRequest} deleteTeamRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTeam: async (teamId: string, deleteTeamRequest: DeleteTeamRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'teamId' is not null or undefined
            assertParamExists('deleteTeam', 'teamId', teamId)
            // verify required parameter 'deleteTeamRequest' is not null or undefined
            assertParamExists('deleteTeam', 'deleteTeamRequest', deleteTeamRequest)
            const localVarPath = `/api/team/{teamId}`
                .replace(`{${"teamId"}}`, encodeURIComponent(String(teamId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteTeamRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} teamId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeam: async (teamId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'teamId' is not null or undefined
            assertParamExists('getTeam', 'teamId', teamId)
            const localVarPath = `/api/team/{teamId}`
                .replace(`{${"teamId"}}`, encodeURIComponent(String(teamId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [from] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTeam: async (from?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/team`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} teamId 
         * @param {UpdateTeamRequest} updateTeamRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTeam: async (teamId: string, updateTeamRequest: UpdateTeamRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'teamId' is not null or undefined
            assertParamExists('updateTeam', 'teamId', teamId)
            // verify required parameter 'updateTeamRequest' is not null or undefined
            assertParamExists('updateTeam', 'updateTeamRequest', updateTeamRequest)
            const localVarPath = `/api/team/{teamId}`
                .replace(`{${"teamId"}}`, encodeURIComponent(String(teamId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateTeamRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TeamApi - functional programming interface
 * @export
 */
export const TeamApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TeamApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} teamId 
         * @param {CreateTeamRequest} createTeamRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTeam(teamId: string, createTeamRequest: CreateTeamRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateTeamResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createTeam(teamId, createTeamRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} teamId 
         * @param {DeleteTeamRequest} deleteTeamRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTeam(teamId: string, deleteTeamRequest: DeleteTeamRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTeam(teamId, deleteTeamRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} teamId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTeam(teamId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetTeamResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTeam(teamId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [from] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listTeam(from?: number, size?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListTeamResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listTeam(from, size, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} teamId 
         * @param {UpdateTeamRequest} updateTeamRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTeam(teamId: string, updateTeamRequest: UpdateTeamRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateTeamResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTeam(teamId, updateTeamRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TeamApi - factory interface
 * @export
 */
export const TeamApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TeamApiFp(configuration)
    return {
        /**
         * 
         * @param {string} teamId 
         * @param {CreateTeamRequest} createTeamRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTeam(teamId: string, createTeamRequest: CreateTeamRequest, options?: any): AxiosPromise<CreateTeamResponse> {
            return localVarFp.createTeam(teamId, createTeamRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} teamId 
         * @param {DeleteTeamRequest} deleteTeamRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTeam(teamId: string, deleteTeamRequest: DeleteTeamRequest, options?: any): AxiosPromise<void> {
            return localVarFp.deleteTeam(teamId, deleteTeamRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} teamId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeam(teamId: string, options?: any): AxiosPromise<GetTeamResponse> {
            return localVarFp.getTeam(teamId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [from] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTeam(from?: number, size?: number, options?: any): AxiosPromise<ListTeamResponse> {
            return localVarFp.listTeam(from, size, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} teamId 
         * @param {UpdateTeamRequest} updateTeamRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTeam(teamId: string, updateTeamRequest: UpdateTeamRequest, options?: any): AxiosPromise<UpdateTeamResponse> {
            return localVarFp.updateTeam(teamId, updateTeamRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TeamApi - object-oriented interface
 * @export
 * @class TeamApi
 * @extends {BaseAPI}
 */
export class TeamApi extends BaseAPI {
    /**
     * 
     * @param {string} teamId 
     * @param {CreateTeamRequest} createTeamRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamApi
     */
    public createTeam(teamId: string, createTeamRequest: CreateTeamRequest, options?: AxiosRequestConfig) {
        return TeamApiFp(this.configuration).createTeam(teamId, createTeamRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} teamId 
     * @param {DeleteTeamRequest} deleteTeamRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamApi
     */
    public deleteTeam(teamId: string, deleteTeamRequest: DeleteTeamRequest, options?: AxiosRequestConfig) {
        return TeamApiFp(this.configuration).deleteTeam(teamId, deleteTeamRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} teamId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamApi
     */
    public getTeam(teamId: string, options?: AxiosRequestConfig) {
        return TeamApiFp(this.configuration).getTeam(teamId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [from] 
     * @param {number} [size] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamApi
     */
    public listTeam(from?: number, size?: number, options?: AxiosRequestConfig) {
        return TeamApiFp(this.configuration).listTeam(from, size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} teamId 
     * @param {UpdateTeamRequest} updateTeamRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamApi
     */
    public updateTeam(teamId: string, updateTeamRequest: UpdateTeamRequest, options?: AxiosRequestConfig) {
        return TeamApiFp(this.configuration).updateTeam(teamId, updateTeamRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TeamMemberApi - axios parameter creator
 * @export
 */
export const TeamMemberApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} teamId 
         * @param {AddTeamMemberRequest} addTeamMemberRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTeamMember: async (teamId: string, addTeamMemberRequest: AddTeamMemberRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'teamId' is not null or undefined
            assertParamExists('addTeamMember', 'teamId', teamId)
            // verify required parameter 'addTeamMemberRequest' is not null or undefined
            assertParamExists('addTeamMember', 'addTeamMemberRequest', addTeamMemberRequest)
            const localVarPath = `/api/team/{teamId}/member`
                .replace(`{${"teamId"}}`, encodeURIComponent(String(teamId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addTeamMemberRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} teamId 
         * @param {number} [from] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTeamMember: async (teamId: string, from?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'teamId' is not null or undefined
            assertParamExists('listTeamMember', 'teamId', teamId)
            const localVarPath = `/api/team/{teamId}/member`
                .replace(`{${"teamId"}}`, encodeURIComponent(String(teamId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} teamId 
         * @param {RemoveTeamMemberRequest} removeTeamMemberRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeTeamMember: async (teamId: string, removeTeamMemberRequest: RemoveTeamMemberRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'teamId' is not null or undefined
            assertParamExists('removeTeamMember', 'teamId', teamId)
            // verify required parameter 'removeTeamMemberRequest' is not null or undefined
            assertParamExists('removeTeamMember', 'removeTeamMemberRequest', removeTeamMemberRequest)
            const localVarPath = `/api/team/{teamId}/member`
                .replace(`{${"teamId"}}`, encodeURIComponent(String(teamId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(removeTeamMemberRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TeamMemberApi - functional programming interface
 * @export
 */
export const TeamMemberApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TeamMemberApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} teamId 
         * @param {AddTeamMemberRequest} addTeamMemberRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addTeamMember(teamId: string, addTeamMemberRequest: AddTeamMemberRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addTeamMember(teamId, addTeamMemberRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} teamId 
         * @param {number} [from] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listTeamMember(teamId: string, from?: number, size?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListTeamMemberResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listTeamMember(teamId, from, size, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} teamId 
         * @param {RemoveTeamMemberRequest} removeTeamMemberRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeTeamMember(teamId: string, removeTeamMemberRequest: RemoveTeamMemberRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeTeamMember(teamId, removeTeamMemberRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TeamMemberApi - factory interface
 * @export
 */
export const TeamMemberApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TeamMemberApiFp(configuration)
    return {
        /**
         * 
         * @param {string} teamId 
         * @param {AddTeamMemberRequest} addTeamMemberRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTeamMember(teamId: string, addTeamMemberRequest: AddTeamMemberRequest, options?: any): AxiosPromise<void> {
            return localVarFp.addTeamMember(teamId, addTeamMemberRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} teamId 
         * @param {number} [from] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTeamMember(teamId: string, from?: number, size?: number, options?: any): AxiosPromise<ListTeamMemberResponse> {
            return localVarFp.listTeamMember(teamId, from, size, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} teamId 
         * @param {RemoveTeamMemberRequest} removeTeamMemberRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeTeamMember(teamId: string, removeTeamMemberRequest: RemoveTeamMemberRequest, options?: any): AxiosPromise<void> {
            return localVarFp.removeTeamMember(teamId, removeTeamMemberRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TeamMemberApi - object-oriented interface
 * @export
 * @class TeamMemberApi
 * @extends {BaseAPI}
 */
export class TeamMemberApi extends BaseAPI {
    /**
     * 
     * @param {string} teamId 
     * @param {AddTeamMemberRequest} addTeamMemberRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamMemberApi
     */
    public addTeamMember(teamId: string, addTeamMemberRequest: AddTeamMemberRequest, options?: AxiosRequestConfig) {
        return TeamMemberApiFp(this.configuration).addTeamMember(teamId, addTeamMemberRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} teamId 
     * @param {number} [from] 
     * @param {number} [size] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamMemberApi
     */
    public listTeamMember(teamId: string, from?: number, size?: number, options?: AxiosRequestConfig) {
        return TeamMemberApiFp(this.configuration).listTeamMember(teamId, from, size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} teamId 
     * @param {RemoveTeamMemberRequest} removeTeamMemberRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamMemberApi
     */
    public removeTeamMember(teamId: string, removeTeamMemberRequest: RemoveTeamMemberRequest, options?: AxiosRequestConfig) {
        return TeamMemberApiFp(this.configuration).removeTeamMember(teamId, removeTeamMemberRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TicketApi - axios parameter creator
 * @export
 */
export const TicketApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} projectId 
         * @param {CreateTicketRequest} createTicketRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTicket: async (projectId: string, createTicketRequest: CreateTicketRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('createTicket', 'projectId', projectId)
            // verify required parameter 'createTicketRequest' is not null or undefined
            assertParamExists('createTicket', 'createTicketRequest', createTicketRequest)
            const localVarPath = `/api/project/{projectId}/ticket`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createTicketRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {DeleteTicketRequest} deleteTicketRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTicket: async (projectId: string, deleteTicketRequest: DeleteTicketRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('deleteTicket', 'projectId', projectId)
            // verify required parameter 'deleteTicketRequest' is not null or undefined
            assertParamExists('deleteTicket', 'deleteTicketRequest', deleteTicketRequest)
            const localVarPath = `/api/project/{projectId}/ticket`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteTicketRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {number} ticketNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTicket: async (projectId: string, ticketNumber: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getTicket', 'projectId', projectId)
            // verify required parameter 'ticketNumber' is not null or undefined
            assertParamExists('getTicket', 'ticketNumber', ticketNumber)
            const localVarPath = `/api/project/{projectId}/ticket/{ticketNumber}`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"ticketNumber"}}`, encodeURIComponent(String(ticketNumber)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [from] 
         * @param {number} [size] 
         * @param {string} [projectId] 
         * @param {string} [assignedUserId] 
         * @param {TicketModeEnum} [mode] 
         * @param {TicketStatusEnum} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTicket: async (from?: number, size?: number, projectId?: string, assignedUserId?: string, mode?: TicketModeEnum, status?: TicketStatusEnum, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ticket`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (projectId !== undefined) {
                localVarQueryParameter['projectId'] = projectId;
            }

            if (assignedUserId !== undefined) {
                localVarQueryParameter['assignedUserId'] = assignedUserId;
            }

            if (mode !== undefined) {
                localVarQueryParameter['mode'] = mode;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {UpdateTicketRequest} updateTicketRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTicket: async (projectId: string, updateTicketRequest: UpdateTicketRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('updateTicket', 'projectId', projectId)
            // verify required parameter 'updateTicketRequest' is not null or undefined
            assertParamExists('updateTicket', 'updateTicketRequest', updateTicketRequest)
            const localVarPath = `/api/project/{projectId}/ticket`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateTicketRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TicketApi - functional programming interface
 * @export
 */
export const TicketApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TicketApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} projectId 
         * @param {CreateTicketRequest} createTicketRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTicket(projectId: string, createTicketRequest: CreateTicketRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateTicketResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createTicket(projectId, createTicketRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} projectId 
         * @param {DeleteTicketRequest} deleteTicketRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTicket(projectId: string, deleteTicketRequest: DeleteTicketRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTicket(projectId, deleteTicketRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} projectId 
         * @param {number} ticketNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTicket(projectId: string, ticketNumber: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetTicketResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTicket(projectId, ticketNumber, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [from] 
         * @param {number} [size] 
         * @param {string} [projectId] 
         * @param {string} [assignedUserId] 
         * @param {TicketModeEnum} [mode] 
         * @param {TicketStatusEnum} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listTicket(from?: number, size?: number, projectId?: string, assignedUserId?: string, mode?: TicketModeEnum, status?: TicketStatusEnum, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListTicketResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listTicket(from, size, projectId, assignedUserId, mode, status, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} projectId 
         * @param {UpdateTicketRequest} updateTicketRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTicket(projectId: string, updateTicketRequest: UpdateTicketRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateTicketResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTicket(projectId, updateTicketRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TicketApi - factory interface
 * @export
 */
export const TicketApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TicketApiFp(configuration)
    return {
        /**
         * 
         * @param {string} projectId 
         * @param {CreateTicketRequest} createTicketRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTicket(projectId: string, createTicketRequest: CreateTicketRequest, options?: any): AxiosPromise<CreateTicketResponse> {
            return localVarFp.createTicket(projectId, createTicketRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {DeleteTicketRequest} deleteTicketRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTicket(projectId: string, deleteTicketRequest: DeleteTicketRequest, options?: any): AxiosPromise<void> {
            return localVarFp.deleteTicket(projectId, deleteTicketRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {number} ticketNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTicket(projectId: string, ticketNumber: number, options?: any): AxiosPromise<GetTicketResponse> {
            return localVarFp.getTicket(projectId, ticketNumber, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [from] 
         * @param {number} [size] 
         * @param {string} [projectId] 
         * @param {string} [assignedUserId] 
         * @param {TicketModeEnum} [mode] 
         * @param {TicketStatusEnum} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTicket(from?: number, size?: number, projectId?: string, assignedUserId?: string, mode?: TicketModeEnum, status?: TicketStatusEnum, options?: any): AxiosPromise<ListTicketResponse> {
            return localVarFp.listTicket(from, size, projectId, assignedUserId, mode, status, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {UpdateTicketRequest} updateTicketRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTicket(projectId: string, updateTicketRequest: UpdateTicketRequest, options?: any): AxiosPromise<UpdateTicketResponse> {
            return localVarFp.updateTicket(projectId, updateTicketRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TicketApi - object-oriented interface
 * @export
 * @class TicketApi
 * @extends {BaseAPI}
 */
export class TicketApi extends BaseAPI {
    /**
     * 
     * @param {string} projectId 
     * @param {CreateTicketRequest} createTicketRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketApi
     */
    public createTicket(projectId: string, createTicketRequest: CreateTicketRequest, options?: AxiosRequestConfig) {
        return TicketApiFp(this.configuration).createTicket(projectId, createTicketRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} projectId 
     * @param {DeleteTicketRequest} deleteTicketRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketApi
     */
    public deleteTicket(projectId: string, deleteTicketRequest: DeleteTicketRequest, options?: AxiosRequestConfig) {
        return TicketApiFp(this.configuration).deleteTicket(projectId, deleteTicketRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} projectId 
     * @param {number} ticketNumber 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketApi
     */
    public getTicket(projectId: string, ticketNumber: number, options?: AxiosRequestConfig) {
        return TicketApiFp(this.configuration).getTicket(projectId, ticketNumber, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [from] 
     * @param {number} [size] 
     * @param {string} [projectId] 
     * @param {string} [assignedUserId] 
     * @param {TicketModeEnum} [mode] 
     * @param {TicketStatusEnum} [status] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketApi
     */
    public listTicket(from?: number, size?: number, projectId?: string, assignedUserId?: string, mode?: TicketModeEnum, status?: TicketStatusEnum, options?: AxiosRequestConfig) {
        return TicketApiFp(this.configuration).listTicket(from, size, projectId, assignedUserId, mode, status, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} projectId 
     * @param {UpdateTicketRequest} updateTicketRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketApi
     */
    public updateTicket(projectId: string, updateTicketRequest: UpdateTicketRequest, options?: AxiosRequestConfig) {
        return TicketApiFp(this.configuration).updateTicket(projectId, updateTicketRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TicketCategoryApi - axios parameter creator
 * @export
 */
export const TicketCategoryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} projectId 
         * @param {string} categoryName 
         * @param {CreateTicketCategoryRequest} createTicketCategoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTicketCategory: async (projectId: string, categoryName: string, createTicketCategoryRequest: CreateTicketCategoryRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('createTicketCategory', 'projectId', projectId)
            // verify required parameter 'categoryName' is not null or undefined
            assertParamExists('createTicketCategory', 'categoryName', categoryName)
            // verify required parameter 'createTicketCategoryRequest' is not null or undefined
            assertParamExists('createTicketCategory', 'createTicketCategoryRequest', createTicketCategoryRequest)
            const localVarPath = `/api/project/{projectId}/ticket-category/{categoryName}`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"categoryName"}}`, encodeURIComponent(String(categoryName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createTicketCategoryRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} categoryName 
         * @param {DeleteTicketCategoryRequest} deleteTicketCategoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTicketCategory: async (projectId: string, categoryName: string, deleteTicketCategoryRequest: DeleteTicketCategoryRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('deleteTicketCategory', 'projectId', projectId)
            // verify required parameter 'categoryName' is not null or undefined
            assertParamExists('deleteTicketCategory', 'categoryName', categoryName)
            // verify required parameter 'deleteTicketCategoryRequest' is not null or undefined
            assertParamExists('deleteTicketCategory', 'deleteTicketCategoryRequest', deleteTicketCategoryRequest)
            const localVarPath = `/api/project/{projectId}/ticket-category/{categoryName}`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"categoryName"}}`, encodeURIComponent(String(categoryName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteTicketCategoryRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} categoryName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTicketCategory: async (projectId: string, categoryName: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getTicketCategory', 'projectId', projectId)
            // verify required parameter 'categoryName' is not null or undefined
            assertParamExists('getTicketCategory', 'categoryName', categoryName)
            const localVarPath = `/api/project/{projectId}/ticket-category/{categoryName}`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"categoryName"}}`, encodeURIComponent(String(categoryName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {number} [from] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTicketCategory: async (projectId: string, from?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('listTicketCategory', 'projectId', projectId)
            const localVarPath = `/api/project/{projectId}/ticket-category`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} categoryName 
         * @param {UpdateTicketCategoryRequest} updateTicketCategoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTicketCategory: async (projectId: string, categoryName: string, updateTicketCategoryRequest: UpdateTicketCategoryRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('updateTicketCategory', 'projectId', projectId)
            // verify required parameter 'categoryName' is not null or undefined
            assertParamExists('updateTicketCategory', 'categoryName', categoryName)
            // verify required parameter 'updateTicketCategoryRequest' is not null or undefined
            assertParamExists('updateTicketCategory', 'updateTicketCategoryRequest', updateTicketCategoryRequest)
            const localVarPath = `/api/project/{projectId}/ticket-category/{categoryName}`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"categoryName"}}`, encodeURIComponent(String(categoryName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateTicketCategoryRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TicketCategoryApi - functional programming interface
 * @export
 */
export const TicketCategoryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TicketCategoryApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} projectId 
         * @param {string} categoryName 
         * @param {CreateTicketCategoryRequest} createTicketCategoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTicketCategory(projectId: string, categoryName: string, createTicketCategoryRequest: CreateTicketCategoryRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateTicketCategoryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createTicketCategory(projectId, categoryName, createTicketCategoryRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} categoryName 
         * @param {DeleteTicketCategoryRequest} deleteTicketCategoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTicketCategory(projectId: string, categoryName: string, deleteTicketCategoryRequest: DeleteTicketCategoryRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTicketCategory(projectId, categoryName, deleteTicketCategoryRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} categoryName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTicketCategory(projectId: string, categoryName: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetTicketCategoryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTicketCategory(projectId, categoryName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} projectId 
         * @param {number} [from] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listTicketCategory(projectId: string, from?: number, size?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListTicketCategoryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listTicketCategory(projectId, from, size, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} categoryName 
         * @param {UpdateTicketCategoryRequest} updateTicketCategoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTicketCategory(projectId: string, categoryName: string, updateTicketCategoryRequest: UpdateTicketCategoryRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateTicketCategoryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTicketCategory(projectId, categoryName, updateTicketCategoryRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TicketCategoryApi - factory interface
 * @export
 */
export const TicketCategoryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TicketCategoryApiFp(configuration)
    return {
        /**
         * 
         * @param {string} projectId 
         * @param {string} categoryName 
         * @param {CreateTicketCategoryRequest} createTicketCategoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTicketCategory(projectId: string, categoryName: string, createTicketCategoryRequest: CreateTicketCategoryRequest, options?: any): AxiosPromise<CreateTicketCategoryResponse> {
            return localVarFp.createTicketCategory(projectId, categoryName, createTicketCategoryRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} categoryName 
         * @param {DeleteTicketCategoryRequest} deleteTicketCategoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTicketCategory(projectId: string, categoryName: string, deleteTicketCategoryRequest: DeleteTicketCategoryRequest, options?: any): AxiosPromise<void> {
            return localVarFp.deleteTicketCategory(projectId, categoryName, deleteTicketCategoryRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} categoryName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTicketCategory(projectId: string, categoryName: string, options?: any): AxiosPromise<GetTicketCategoryResponse> {
            return localVarFp.getTicketCategory(projectId, categoryName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {number} [from] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTicketCategory(projectId: string, from?: number, size?: number, options?: any): AxiosPromise<ListTicketCategoryResponse> {
            return localVarFp.listTicketCategory(projectId, from, size, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} categoryName 
         * @param {UpdateTicketCategoryRequest} updateTicketCategoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTicketCategory(projectId: string, categoryName: string, updateTicketCategoryRequest: UpdateTicketCategoryRequest, options?: any): AxiosPromise<UpdateTicketCategoryResponse> {
            return localVarFp.updateTicketCategory(projectId, categoryName, updateTicketCategoryRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TicketCategoryApi - object-oriented interface
 * @export
 * @class TicketCategoryApi
 * @extends {BaseAPI}
 */
export class TicketCategoryApi extends BaseAPI {
    /**
     * 
     * @param {string} projectId 
     * @param {string} categoryName 
     * @param {CreateTicketCategoryRequest} createTicketCategoryRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketCategoryApi
     */
    public createTicketCategory(projectId: string, categoryName: string, createTicketCategoryRequest: CreateTicketCategoryRequest, options?: AxiosRequestConfig) {
        return TicketCategoryApiFp(this.configuration).createTicketCategory(projectId, categoryName, createTicketCategoryRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} projectId 
     * @param {string} categoryName 
     * @param {DeleteTicketCategoryRequest} deleteTicketCategoryRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketCategoryApi
     */
    public deleteTicketCategory(projectId: string, categoryName: string, deleteTicketCategoryRequest: DeleteTicketCategoryRequest, options?: AxiosRequestConfig) {
        return TicketCategoryApiFp(this.configuration).deleteTicketCategory(projectId, categoryName, deleteTicketCategoryRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} projectId 
     * @param {string} categoryName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketCategoryApi
     */
    public getTicketCategory(projectId: string, categoryName: string, options?: AxiosRequestConfig) {
        return TicketCategoryApiFp(this.configuration).getTicketCategory(projectId, categoryName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} projectId 
     * @param {number} [from] 
     * @param {number} [size] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketCategoryApi
     */
    public listTicketCategory(projectId: string, from?: number, size?: number, options?: AxiosRequestConfig) {
        return TicketCategoryApiFp(this.configuration).listTicketCategory(projectId, from, size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} projectId 
     * @param {string} categoryName 
     * @param {UpdateTicketCategoryRequest} updateTicketCategoryRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketCategoryApi
     */
    public updateTicketCategory(projectId: string, categoryName: string, updateTicketCategoryRequest: UpdateTicketCategoryRequest, options?: AxiosRequestConfig) {
        return TicketCategoryApiFp(this.configuration).updateTicketCategory(projectId, categoryName, updateTicketCategoryRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser: async (userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getUser', 'userId', userId)
            const localVarPath = `/api/user/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [from] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUser: async (from?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {UpdateUserRequest} updateUserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser: async (userId: string, updateUserRequest: UpdateUserRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('updateUser', 'userId', userId)
            // verify required parameter 'updateUserRequest' is not null or undefined
            assertParamExists('updateUser', 'updateUserRequest', updateUserRequest)
            const localVarPath = `/api/user/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateUserRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        whoAmI: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/who-am-i`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUser(userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetUserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUser(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [from] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listUser(from?: number, size?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListUserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listUser(from, size, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} userId 
         * @param {UpdateUserRequest} updateUserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUser(userId: string, updateUserRequest: UpdateUserRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateUserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUser(userId, updateUserRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async whoAmI(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WhoAmIResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.whoAmI(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserApiFp(configuration)
    return {
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser(userId: string, options?: any): AxiosPromise<GetUserResponse> {
            return localVarFp.getUser(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [from] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUser(from?: number, size?: number, options?: any): AxiosPromise<ListUserResponse> {
            return localVarFp.listUser(from, size, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userId 
         * @param {UpdateUserRequest} updateUserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser(userId: string, updateUserRequest: UpdateUserRequest, options?: any): AxiosPromise<UpdateUserResponse> {
            return localVarFp.updateUser(userId, updateUserRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        whoAmI(options?: any): AxiosPromise<WhoAmIResponse> {
            return localVarFp.whoAmI(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getUser(userId: string, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).getUser(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [from] 
     * @param {number} [size] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public listUser(from?: number, size?: number, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).listUser(from, size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userId 
     * @param {UpdateUserRequest} updateUserRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public updateUser(userId: string, updateUserRequest: UpdateUserRequest, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).updateUser(userId, updateUserRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public whoAmI(options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).whoAmI(options).then((request) => request(this.axios, this.basePath));
    }
}


