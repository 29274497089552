import { memo, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Stack from 'react-bootstrap/Stack';
import Nav from 'react-bootstrap/Nav';

import { useGlobalConfigContext } from "../providers/GlobalConfig"
import { LoginRequired, AlreadyLogin } from "../providers/LoginUser"
import { LoginLayout } from "../layout/LoginLayout"
import {
  TeamApi,

  GetTeamResponse,
  EscalationConfigModeEnum,
  ContactConfigMailLevelEnum,
} from "../openapi-generator/admin-api/";

type PageParam = {
  getTeamResponse: GetTeamResponse;
}

export const TeamDetailPage = memo(() => {

  const adminApiConfiguration = useGlobalConfigContext().adminApiConfiguration;
  const teamApi = new TeamApi(adminApiConfiguration);

  const navigate = useNavigate();
  const urlParams = useParams<{ teamId: string }>();
  const [param, setParam] = useState<PageParam>();

  useEffect(()=>{(async()=>{

    const [
      getTeamResponse
    ] = await Promise.all([
      teamApi.getTeam(urlParams.teamId || ""),
    ]);

    setParam({
      getTeamResponse: getTeamResponse.data
    });

  })();},[]);

  if (!!param) {

    return (
      <LoginLayout>
        <Stack direction="horizontal" gap={2}>
          <h2 className="p-2">{param.getTeamResponse.teamName} (id:{param.getTeamResponse.teamId})</h2>
          <div className="p-2"><a href={ `/team/${encodeURIComponent(param.getTeamResponse.teamId)}/edit` }>edit</a></div>
        </Stack>

        <Stack direction="horizontal" gap={2}>
          <div className="p-2"><a href={ `/team/${encodeURIComponent(param.getTeamResponse.teamId)}` }>基本情報</a></div>
          <div className="p-2"><a href={ `/team/${encodeURIComponent(param.getTeamResponse.teamId)}/member` }>メンバー</a></div>
        </Stack>
        <hr />
        <h3>基本情報</h3>
        <dl>
          <dt>description</dt>
          <dd>{param.getTeamResponse.description}</dd>

          <dt>parentTeam</dt>
          <dd>
            { param.getTeamResponse.parentTeam
              && <a href={ `/team/${encodeURIComponent(param.getTeamResponse.parentTeam.teamId)}` }>{param.getTeamResponse.parentTeam.teamName} </a>
            }
            { !param.getTeamResponse.parentTeam
              && <>----</>
            }
          </dd>
        </dl>

        <h3>escalationConfig</h3>
        { param.getTeamResponse.escalationConfig
          && <dl>
            <dt>mode</dt>
            <dd>{param.getTeamResponse.escalationConfig.mode}</dd>

            <dt>waitSeconds</dt>
            <dd>{param.getTeamResponse.escalationConfig.waitSeconds}</dd>

            <dt>tryCount</dt>
            <dd>{param.getTeamResponse.escalationConfig.tryCount}</dd>
          </dl>
        }
        { !param.getTeamResponse.escalationConfig
          && <p>設定なし</p>
        }

        <h3>contactConfig</h3>
        { param.getTeamResponse.contactConfig
          && <>
             { param.getTeamResponse.contactConfig.mail
               && <dl>
                 <dt>mail</dt>
                 <dd>{param.getTeamResponse.contactConfig.mail.level}&nbsp;{param.getTeamResponse.contactConfig.mail.mailAddress}</dd>
               </dl>
             }
          </>
        }
        { !param.getTeamResponse.contactConfig
          && <p>設定なし</p>
        }


      </LoginLayout>
    );
  } else {
    return <p>loading...</p>
  }
});