import { memo, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm, SubmitHandler } from "react-hook-form";

import Stack from 'react-bootstrap/Stack';
import Nav from 'react-bootstrap/Nav';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

import { useGlobalConfigContext } from "../providers/GlobalConfig"
import { LoginRequired, AlreadyLogin } from "../providers/LoginUser"
import { LoginLayout } from "../layout/LoginLayout"
import {
  TeamApi,
  TeamMemberApi,
  UserApi,

  GetTeamResponse,
  ListTeamMemberResponse,
  ListUserResponse,
  TeamMemberForList,
  UserForList,
  AddTeamMemberRequest,
} from "../openapi-generator/admin-api/";

type PageParam = {
  getTeamResponse: GetTeamResponse;
  teamMemberList: TeamMemberForList[];
  nonMemberUserList: UserForList[];
}

type MemberAddForm = {
  data: AddTeamMemberRequest;
}

export const TeamMemberPage = memo(() => {

  const adminApiConfiguration = useGlobalConfigContext().adminApiConfiguration;
  const userApi = new UserApi(adminApiConfiguration);
  const teamApi = new TeamApi(adminApiConfiguration);
  const teamMemberApi = new TeamMemberApi(adminApiConfiguration);

  const navigate = useNavigate();
  const urlParams = useParams<{ teamId: string }>();
  const [param, setParam] = useState<PageParam>();
  const { handleSubmit, register, reset, formState: { errors, isValid, isSubmitting } }
     = useForm<MemberAddForm>();

  const teamId = urlParams.teamId || ""

  const onSubmit: SubmitHandler<MemberAddForm> = async (form) => {
    console.log(form);
    var response = await teamMemberApi.addTeamMember(teamId, form.data);
    console.log(response);
    navigate(`/team/${teamId}/member`);
  };

  useEffect(()=>{(async()=>{

    const [
      getTeamResponse,
      listTeamMemberResponse,
      listUserResponse
    ] = await Promise.all([
      teamApi.getTeam(teamId),
      teamMemberApi.listTeamMember(teamId, 0, 1000),
      userApi.listUser(0, 1000)
    ]);

    let teamMemberList: TeamMemberForList[] = listTeamMemberResponse.data.results;
    let userList = listUserResponse.data.results;

    setParam({
      getTeamResponse: getTeamResponse.data,
      teamMemberList: teamMemberList,
      nonMemberUserList: userList.filter((item) => !teamMemberList.find((tm) => tm.user.userId == item.userId)),
    });

  })();},[]);

  if (!!param) {

    return (
      <LoginLayout>
        <Stack direction="horizontal" gap={2}>
          <h2 className="p-2">{param.getTeamResponse.teamName} (id:{param.getTeamResponse.teamId})</h2>
          <div className="p-2"><a href={ `/team/${param.getTeamResponse.teamId}/edit` }>edit</a></div>
        </Stack>

        <Stack direction="horizontal" gap={2}>
          <div className="p-2"><a href={ `/team/${param.getTeamResponse.teamId}` }>基本情報</a></div>
          <div className="p-2">メンバー</div>
          <div className="p-2"><a href={ `/team/${param.getTeamResponse.teamId}/project` }>プロジェクト</a></div>
        </Stack>
        <hr />
        <h3>メンバー追加</h3>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Group className="mb-3" controlId="userId">
            <Form.Label>userId</Form.Label>
            <Form.Select { ...register('data.userId', { required: false }) } >
              <option>Default select</option>
              { param.nonMemberUserList.map((item, _) => <option value={item.userId}>{item.userName}</option>) }
            </Form.Select>
            { errors.data && errors.data.userId
              && <span>Please provide a valid userId</span>
            }
          </Form.Group>
          <Button variant="primary" type="submit">Submit</Button>
        </Form>

        <hr />
        <h3>メンバー一覧</h3>
        { param.teamMemberList.length > 0
          && <ul>{
             param.teamMemberList.map((item, index) =>
               <li><a href={`/user/${item.user.userId}`}>{item.user.userName}</a></li>
             )
          }</ul>
        }

      </LoginLayout>
    );
  } else {
    return <p>loading...</p>
  }
});