import { memo, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { LoginRequired, AlreadyLogin } from "../providers/LoginUser"
import { LoginLayout } from "../layout/LoginLayout"

import { useGlobalConfigContext } from "../providers/GlobalConfig"
import {
  TeamApi,

  ListTeamResponse
} from "../openapi-generator/admin-api/";

type PageParam = {
  listTeamResponse: ListTeamResponse;
}

export const TeamListPage = memo(() => {

  const adminApiConfiguration = useGlobalConfigContext().adminApiConfiguration;
  const teamApi = new TeamApi(adminApiConfiguration);

  const navigate = useNavigate();
  const [param, setParam] = useState<PageParam>()

  useEffect(()=>{(async()=>{

    const listTeamResponse = await teamApi.listTeam(0, 1000);

    setParam({
      listTeamResponse: listTeamResponse.data
    });
  })();},[]);

  if (!!param) {

    return (
      <LoginLayout>
        <p>チーム一覧のページです&nbsp;<Link to="/team-create">New</Link></p>
        <p>
          チームはプロジェクトに紐づけることができます。親子関係を持っていて、親チームはインシデントが発生した際のエスカレーション先となります ※エスカレーション機能は実装中<br/>
          想定している親子関係は 【役員→クオリティアップチーム→案件カタリスト→案件メンバー】 のような構図になります。
        </p>
        { param.listTeamResponse.results.length > 0
          && <ul>{
             param.listTeamResponse.results.map((item, index) =>
               <li><a href={ `/team/${encodeURIComponent(item.teamId)}` }>{item.teamName}</a></li>
             )
          }</ul>
        }
      </LoginLayout>
    );
  } else {
    return <p>load....</p>
  }
});