import { memo, useState, useEffect, FormEvent } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm, SubmitHandler } from "react-hook-form";

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

import { useGlobalConfigContext } from "../providers/GlobalConfig"
import { LoginRequired, AlreadyLogin } from "../providers/LoginUser"
import { LoginLayout } from "../layout/LoginLayout"
import {
  UserApi,
  TicketApi,
  ProjectApi,
  TicketCategoryApi,

  GetProjectResponse,
  TicketForUpdate,
  ListUserResponse,
  UserForList,
  TicketCategoryForList,
  ProjectForField,
  TicketModeEnum,
  TicketStatusEnum,
  CreateTicketRequest,
} from "../openapi-generator/admin-api/";

type PageParam = {
  project: ProjectForField
  userList: UserForList[];
  ticketCategoryList: TicketCategoryForList[];
}
type PageForm = {
  projectId: string;
  ticketNumber: number;
  data: TicketForUpdate;
  versionExpect: number;
}

export const TicketUpdatePage = memo(() => {

  const adminApiConfiguration = useGlobalConfigContext().adminApiConfiguration;
  const userApi = new UserApi(adminApiConfiguration);
  const ticketApi = new TicketApi(adminApiConfiguration);
  const projectApi = new ProjectApi(adminApiConfiguration);
  const ticketCategoryApi = new TicketCategoryApi(adminApiConfiguration);

  const navigate = useNavigate();
  const urlParams = useParams<{ projectId: string, ticketNumber: string }>();
  const [param, setParam] = useState<PageParam>();
  const { handleSubmit, register, reset, formState: { errors, isValid, isSubmitting } }
   = useForm<PageForm>();

  const onSubmit: SubmitHandler<PageForm> = async (form) => {
    console.log(form);
    var response = await ticketApi.updateTicket(form.projectId || "", {
      ticketNumber: form.ticketNumber,
      ticket: {
        mode: form.data.mode,
        categoryName: form.data.categoryName,
        status: form.data.status,
        title: form.data.title,
        body: form.data.body,
        assignedUserId: form.data.assignedUserId != "" ? form.data.assignedUserId : undefined,
      },
      versionExpect: form.versionExpect
    });
    let createTicketResponse = response.data;
    console.log(response);
    navigate(`/ticket/${encodeURIComponent(createTicketResponse.projectId)}/${encodeURIComponent(createTicketResponse.ticketNumber)}`);
  };

  useEffect(()=>{(async()=>{

    const [
      getTicketResponse,
      listTicketCategoryResponse,
      listUserResponse,
    ] = await Promise.all([
      ticketApi.getTicket(urlParams.projectId || "", Number(urlParams.ticketNumber)),
      ticketCategoryApi.listTicketCategory(urlParams.projectId || ""),
      userApi.listUser(),
    ]);

    let userList = listUserResponse.data.results;
    let ticketCategoryList = listTicketCategoryResponse.data.results;

    reset({
      projectId: getTicketResponse.data.project.projectId,
      ticketNumber: getTicketResponse.data.ticketNumber,
      data: {
        mode: getTicketResponse.data.mode,
        categoryName: getTicketResponse.data.category.categoryName,
        status: getTicketResponse.data.status,
        title: getTicketResponse.data.title,
        body: getTicketResponse.data.body,
        assignedUserId: getTicketResponse.data.assignedUser && getTicketResponse.data.assignedUser.userId
      },
      versionExpect: getTicketResponse.data.version
    })

    setParam({
      project: getTicketResponse.data.project,
      userList: userList,
      ticketCategoryList: ticketCategoryList
    });

  })();},[reset]);

  if (!!param) {

    return (
      <LoginLayout>
        <h2 className="p-2">{param.project.projectName} (id:{param.project.projectId})</h2>

        <Form onSubmit={handleSubmit(onSubmit)}>

          <Form.Group className="mb-3" controlId="mode">
            <Form.Label>モード選択</Form.Label>
            <Form.Text muted>
              チケットのモードを選択してください<br />
              INCIDENT: 障害。担当者が決まるまでエスカレーションフローが作動します<br />
              MAINTENANCE: メンテナンス。期日までに対応してください<br />
              TASK: タスク。<br />
            </Form.Text>
            <Form.Select { ...register('data.mode', { required: true }) } >
              <option>Default select</option>
              { Object.entries(TicketModeEnum).map(([key,value]) => <option value={value}>{key}</option>) }
            </Form.Select>
            { errors.data && errors.data.mode
              && <span>Please provide a valid mode</span>
            }
          </Form.Group>
          <Form.Group className="mb-3" controlId="categoryName">
            <Form.Label>カテゴリ</Form.Label>
            <Form.Text muted>
              チケットのカテゴリを選択してください<br />
              カテゴリは<a href={ `/ticket-category/${encodeURIComponent(param.project.projectId)}/create` }>ここ</a>から登録します。<br/>
            </Form.Text>
            <Form.Select { ...register('data.categoryName', { required: true }) } >
              <option>Default select</option>
              { param.ticketCategoryList.map((item, _) => <option value={item.categoryName}>{item.categoryName}</option>) }
            </Form.Select>
            { errors.data && errors.data.categoryName
              && <span>Please provide a valid categoryName</span>
            }
          </Form.Group>
          <Form.Group className="mb-3" controlId="status">
            <Form.Label>ステータス</Form.Label>
            <Form.Text muted>
              チケットのステータスを選択してください<br />
              終わったものはクローズしましょう<br />
            </Form.Text>
            <Form.Select { ...register('data.status', { required: true }) } >
              <option>Default select</option>
              { Object.entries(TicketStatusEnum).map(([key,value]) => <option value={value}>{key}</option>) }
            </Form.Select>
            { errors.data && errors.data.status
              && <span>Please provide a valid status</span>
            }
          </Form.Group>
          <Form.Group className="mb-3" controlId="assignedUserId">
            <Form.Label>担当者</Form.Label>
            <Form.Text muted>
              このチケットの担当者を選択してください<br />
            </Form.Text>
            <Form.Select { ...register('data.assignedUserId', { required: false }) } >
              <option value="">Default select</option>
              { param.userList.map((item, _) => <option value={item.userId}>{item.userName}</option>) }
            </Form.Select>
            { errors.data && errors.data.categoryName
              && <span>Please provide a valid assignedUserId</span>
            }
          </Form.Group>
          <Form.Group className="mb-3" controlId="title">
            <Form.Label>タイトル</Form.Label>
            <Form.Control { ...register('data.title', { required: true }) } />
            { errors.data && errors.data.title
              && <span>Please provide a valid projectName</span>
            }
          </Form.Group>
          <Form.Group className="mb-3" controlId="body">
            <Form.Label>本文</Form.Label>
            <Form.Control { ...register('data.body', { required: true }) } as="textarea"/>
            { errors.data && errors.data.body
              && <span>Please provide a valid body</span>
            }
          </Form.Group>




          <Button variant="primary" type="submit">Submit</Button>
        </Form>
      </LoginLayout>
    );
  } else {
    return <p>loading...</p>
  }
});