import { memo, useState, useEffect, FormEvent } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm, SubmitHandler } from "react-hook-form";

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

import { useGlobalConfigContext } from "../providers/GlobalConfig"
import { LoginRequired, AlreadyLogin } from "../providers/LoginUser"
import { LoginLayout } from "../layout/LoginLayout"
import {
  TeamApi,

  TeamForUpdate,
  ListTeamResponse,
  EscalationConfigModeEnum,
  ContactConfigMailLevelEnum,
} from "../openapi-generator/admin-api/";

type PageParam = {
  listTeamResponse: ListTeamResponse;
}
type PageForm = {
  teamId: string;
  data: TeamForUpdate;
  versionExpect: number;
}

export const TeamEditPage = memo(() => {

  const adminApiConfiguration = useGlobalConfigContext().adminApiConfiguration;
  const teamApi = new TeamApi(adminApiConfiguration);

  const navigate = useNavigate();
  const urlParams = useParams<{ teamId: string }>();
  const [param, setParam] = useState<PageParam>();
  const { handleSubmit, register, reset, formState: { errors, isValid, isSubmitting } }
   = useForm<PageForm>();

  const onSubmit: SubmitHandler<PageForm> = async (form) => {
    console.log(form);
    var response = await teamApi.updateTeam(form.teamId, {
      team: form.data,
      versionExpect: form.versionExpect
    });
    console.log(response);
    navigate("/team");
  };

  useEffect(()=>{(async()=>{

    const [target, listTeamResponse] = await Promise.all([
      teamApi.getTeam(urlParams.teamId || ""),
      teamApi.listTeam(0, 1000),
    ]);

    reset({
      teamId: target.data.teamId,
      data: {
        teamName: target.data.teamName,
        description: target.data.description,
        parentTeamId: target.data.parentTeam && target.data.parentTeam.teamId ,
        escalationConfig: target.data.escalationConfig,
        contactConfig: target.data.contactConfig
      },
      versionExpect: target.data.version
    });

    setParam({
      listTeamResponse: listTeamResponse.data
    });

  })();},[reset]);

  if (!!param) {

    return (
      <LoginLayout>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Group className="mb-3" controlId="teamId">
            <Form.Label>teamId</Form.Label>
            <Form.Control { ...register('teamId', { required: true }) } disabled readOnly/>
            <Form.Text muted>
              チームのユニークID ※変更不可
            </Form.Text>
            { errors.teamId
              && <span>Please provide a valid teamId</span>
            }
          </Form.Group>
          <Form.Group className="mb-3" controlId="teamName">
            <Form.Label>teamName</Form.Label>
            <Form.Text muted>
              チームの名称 ラベルです ※必須
            </Form.Text>
            <Form.Control { ...register('data.teamName', { required: true }) } />
            { errors.data && errors.data.teamName
              && <span>Please provide a valid teamName</span>
            }
          </Form.Group>
          <Form.Group className="mb-3" controlId="description">
            <Form.Label>description</Form.Label>
            <Form.Control { ...register('data.description', { required: false }) } />
            <Form.Text muted>
              チームの説明やメモを入れてください
            </Form.Text>
            { errors.data && errors.data.description
              && <span>Please provide a valid description</span>
            }
          </Form.Group>
          <Form.Group className="mb-3" controlId="parentTeamId">
            <Form.Label>parentTeamId</Form.Label>
            <Form.Text muted>
              親チームを選択してください<br/>
              未選択の場合、エスカレーションの終端となります
            </Form.Text>
            <Form.Select { ...register('data.parentTeamId', { required: false }) } >
              <option>Default select</option>
              { param.listTeamResponse.results.map((item, _) => <option value={item.teamId}>{item.teamName}</option>) }
            </Form.Select>
            { errors.data && errors.data.parentTeamId
              && <span>Please provide a valid parentTeamId</span>
            }
          </Form.Group>


          <Form.Group className="mb-3" controlId="escalationMode">
            <Form.Label>mode</Form.Label>
            <Form.Select { ...register('data.escalationConfig.mode', { required: true }) }>
              <option>Default select</option>
              { Object.entries(EscalationConfigModeEnum).map(([key,value]) => <option value={value}>{key}</option>) }
            </Form.Select>
            <Form.Text muted>
              エスカレーション通知のモード選択です<br/>
              ALL: チームメンバー全員に一斉に通知が飛びます<br/>
              RANDOM: チームメンバーの一人にランダムで通知が飛びます
            </Form.Text>
            { errors.data && errors.data.escalationConfig && errors.data.escalationConfig.mode
              && <span>Please provide a valid escalationConfig.mode</span>
            }
          </Form.Group>
          <Form.Group className="mb-3" controlId="escalationWaitSeconds">
            <Form.Label>escalationWaitSeconds</Form.Label>
            <Form.Control { ...register('data.escalationConfig.waitSeconds', { required: true }) } />
            <Form.Text muted>
              通知をしたがアサインがされない場合の再通知までの待ち時間を秒単位で設定します
            </Form.Text>
            { errors.data && errors.data.escalationConfig && errors.data.escalationConfig.waitSeconds
              && <span>Please provide a valid escalationConfig.waitSeconds</span>
            }
          </Form.Group>
          <Form.Group className="mb-3" controlId="escalationTryCount">
            <Form.Label>escalationTryCount</Form.Label>
            <Form.Text muted>
              インシデントにアサインされないまま通知をN回すると親チームへエスカレーションされます<br/>
              ※親チームが設定されていない場合は引き続きこのチームに通知されます
            </Form.Text>
            <Form.Control { ...register('data.escalationConfig.tryCount', { required: true }) } />
            { errors.data && errors.data.escalationConfig && errors.data.escalationConfig.tryCount
              && <span>Please provide a valid escalationConfig.tryCount</span>
            }
          </Form.Group>


          <Form.Group className="mb-3" controlId="contactLevel">
            <Form.Label>contactLevel</Form.Label>
            <Form.Select { ...register('data.contactConfig.mail.level', { required: false }) } >
              <option>Default select</option>
              { Object.entries(ContactConfigMailLevelEnum).map(([key,value]) => <option value={value}>{key}</option>) }
            </Form.Select>
            { errors.data && errors.data.contactConfig && errors.data.contactConfig.mail && errors.data.contactConfig.mail.level
              && <span>Please provide a valid contactConfig.mail.level</span>
            }
          </Form.Group>
          <Form.Group className="mb-3" controlId="contactMailAddress">
            <Form.Label>contactMailAddress</Form.Label>
            <Form.Control { ...register('data.contactConfig.mail.mailAddress', { required: false }) } />
            { errors.data && errors.data.contactConfig && errors.data.contactConfig.mail && errors.data.contactConfig.mail.mailAddress
              && <span>Please provide a valid contactConfig.mail.mailAddress</span>
            }
          </Form.Group>


          <Button variant="primary" type="submit">Submit</Button>
        </Form>
      </LoginLayout>
    );
  } else {
    return <p>loading...</p>
  }
});