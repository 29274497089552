import { memo, useState, useEffect, FormEvent } from "react";
import { useNavigate } from "react-router-dom";
import { useForm, SubmitHandler } from "react-hook-form";

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

import { useGlobalConfigContext } from "../providers/GlobalConfig"
import { LoginRequired, AlreadyLogin } from "../providers/LoginUser"
import { LoginLayout } from "../layout/LoginLayout"
import {
  TeamApi,
  ProjectApi,

  ProjectForUpdate,
  ListTeamResponse,
  EscalationConfigModeEnum,
  ContactConfigMailLevelEnum,
} from "../openapi-generator/admin-api/"

type PageParam = {
  listTeamResponse: ListTeamResponse;
}
type PageForm = {
  projectId: string;
  data: ProjectForUpdate;
}

export const ProjectCreatePage = memo(() => {

  const adminApiConfiguration = useGlobalConfigContext().adminApiConfiguration;
  const teamApi = new TeamApi(adminApiConfiguration);
  const projectApi = new ProjectApi(adminApiConfiguration);

  const navigate = useNavigate();
  const [param, setParam] = useState<PageParam>();
  const { handleSubmit, register, reset, formState: { errors, isValid, isSubmitting } }
   = useForm<PageForm>();

  const onSubmit: SubmitHandler<PageForm> = async (form) => {
    console.log(form);
    var response = await projectApi.createProject(form.projectId, {
      project: form.data
    });
    console.log(response);
    navigate("/project");
  };

  useEffect(()=>{(async()=>{

    const listTeamResponse = await teamApi.listTeam(0, 1000);

    // reset()

    setParam({
      listTeamResponse: listTeamResponse.data
    });

  })();},[reset]);

  if (!!param) {

    return (
      <LoginLayout>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Group className="mb-3" controlId="projectId">
            <Form.Label>プロジェクトID</Form.Label>
            <Form.Control { ...register('projectId', { required: true }) } />
            { errors.projectId
              && <span>Please provide a valid projectId</span>
            }
          </Form.Group>
          <Form.Group className="mb-3" controlId="projectName">
            <Form.Label>プロジェクト名</Form.Label>
            <Form.Text muted>
              プロジェクトの名称。ラベルです。
            </Form.Text>
            <Form.Control { ...register('data.projectName', { required: true }) } />
            { errors.data && errors.data.projectName
              && <span>Please provide a valid projectName</span>
            }
          </Form.Group>
          <Form.Group className="mb-3" controlId="description">
            <Form.Label>説明</Form.Label>
            <Form.Text muted>
              プロジェクトの説明。経理コードやDriveのURLなどをメモしておくことができます
            </Form.Text>
            <Form.Control { ...register('data.description', { required: false }) } />
            { errors.data && errors.data.description
              && <span>Please provide a valid description</span>
            }
          </Form.Group>
          <Form.Group className="mb-3" controlId="parentTeamId">
            <Form.Label>担当チーム</Form.Label>
            <Form.Text muted>
              プロジェクトの担当チームを設定します。このチームはインシデントが起きた際などの連絡先になります。
            </Form.Text>
            <Form.Select { ...register('data.teamId', { required: true }) } >
              <option>Default select</option>
              { param.listTeamResponse.results.map((item, _) => <option value={item.teamId}>{item.teamName}</option>) }
            </Form.Select>
            { errors.data && errors.data.teamId
              && <span>Please provide a valid teamId</span>
            }
          </Form.Group>



          <Button variant="primary" type="submit">Submit</Button>
        </Form>
      </LoginLayout>
    );
  } else {
    return <p>loading...</p>
  }
});