import { memo, useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Table from 'react-bootstrap/Table';

import { useGlobalConfigContext } from "../providers/GlobalConfig"
import { LoginRequired, AlreadyLogin } from "../providers/LoginUser"
import { LoginLayout } from "../layout/LoginLayout"
import {
  TicketApi,

  TicketForList,
  TicketModeEnum,
  TicketStatusEnum,
} from "../openapi-generator/admin-api/";

type PageParam = {
  ticketForList: TicketForList[];
}

export const TicketListPage = memo(() => {

  const adminApiConfiguration = useGlobalConfigContext().adminApiConfiguration;
  const ticketApi = new TicketApi(adminApiConfiguration);

  const navigate = useNavigate();
  const [param, setParam] = useState<PageParam>();
  const search = useLocation().search;
  const query = new URLSearchParams(search);

  useEffect(()=>{(async()=>{

    const listTicketResponse = await ticketApi.listTicket(
      query.has("from") ? Number(query.get("from")) : undefined,
      query.has("size") ? Number(query.get("size")) : undefined,
      query.get("projectId") || undefined,
      query.get("assignedUserId") || undefined,
      query.get("mode") as TicketModeEnum || undefined,
      query.get("status") as TicketStatusEnum || undefined,
    );

    let ticketForList: TicketForList[] = listTicketResponse.data.results;

    setParam({
      ticketForList: ticketForList
    });
  })();},[]);

  if (!!param) {

    return (
      <LoginLayout>
        <p>Ticketページです</p>

        <a href="/ticket?mode=INCIDENT&status=OPEN">未完了インシデント</a>&nbsp;|&nbsp;
        <a href="/ticket?mode=MAINTENANCE&status=OPEN">未完了メンテナンス</a>

        <hr />
        { param.ticketForList.length > 0
          && <Table>
            <thead>
              <tr>
                <td>Mode</td>
                <td>Status</td>
                <td>Project</td>
                <td>AssignedUser</td>
                <td>Title</td>
                <td>Action</td>
              </tr>
            </thead>
            <tbody>{
              param.ticketForList.map((item, index) =>
                <tr>
                  <td>{item.mode}</td>
                  <td>{item.status}</td>
                  <td>{item.project.projectName}</td>
                  <td>{ item.assignedUser
                    && <a href={ `/user/${encodeURIComponent(item.assignedUser.userId)}` }>{item.assignedUser.userName} </a>
                  }
                  { !item.assignedUser
                    && <>----</>
                  }</td>
                  <td><a href={ `/ticket/${encodeURIComponent(item.project.projectId)}/${item.ticketNumber}` }>{item.title}</a></td>
                  <td><a href={ `/ticket/${encodeURIComponent(item.project.projectId)}/${item.ticketNumber}/edit` }>edit</a></td>
                </tr>
              )
            }</tbody>
          </Table>
        }
      </LoginLayout>
    );
  } else {
    return <p>load....</p>
  }
});