import { memo, useState, useEffect, FormEvent } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm, SubmitHandler } from "react-hook-form";

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

import { useGlobalConfigContext } from "../providers/GlobalConfig"
import { LoginRequired, AlreadyLogin } from "../providers/LoginUser"
import { LoginLayout } from "../layout/LoginLayout"
import {
  UserApi,

  UserForUpdate,
  EscalationConfigModeEnum,
  ContactConfigMailLevelEnum,
} from "../openapi-generator/admin-api/";

type PageParam = {
}
type PageForm = {
  userId: string;
  data: UserForUpdate;
  versionExpect: number;
}

export const UserEditPage = memo(() => {

  const adminApiConfiguration = useGlobalConfigContext().adminApiConfiguration;
  const userApi = new UserApi(adminApiConfiguration);

  const navigate = useNavigate();
  const urlParams = useParams<{ userId: string }>();
  const [param, setParam] = useState<PageParam>();
  const { handleSubmit, register, reset, formState: { errors, isValid, isSubmitting } }
   = useForm<PageForm>();

  const onSubmit: SubmitHandler<PageForm> = async (form) => {
    console.log(form);
    var response = await userApi.updateUser(form.userId, {
      user: form.data,
      versionExpect: form.versionExpect
    });
    console.log(response);
    navigate("/user");
  };

  useEffect(()=>{(async()=>{

    const [target] = await Promise.all([
      userApi.getUser(urlParams.userId || ""),
    ]);

    reset({
      userId: target.data.userId,
      data: {
        userName: target.data.userName,
        contactConfig: target.data.contactConfig
      },
      versionExpect: target.data.version
    });

    setParam({
    });

  })();},[reset]);

  if (!!param) {

    return (
      <LoginLayout>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Group className="mb-3" controlId="userId">
            <Form.Label>ユーザID</Form.Label>
            <Form.Control { ...register('userId', { required: true }) } disabled readOnly/>
            <Form.Text muted>
              ユーザのユニークID ※変更不可
            </Form.Text>
            { errors.userId
              && <span>Please provide a valid userId</span>
            }
          </Form.Group>
          <Form.Group className="mb-3" controlId="userName">
            <Form.Label>teamName</Form.Label>
            <Form.Control { ...register('data.userName', { required: true }) } />
            <Form.Text muted>
              ユーザ名 ラベルです ※必須
            </Form.Text>
            { errors.data && errors.data.userName
              && <span>Please provide a valid userName</span>
            }
          </Form.Group>


          <Form.Group className="mb-3" controlId="contactLevel">
            <Form.Label>contactLevel</Form.Label>
            <Form.Select { ...register('data.contactConfig.mail.level', { required: false }) } >
              <option>Default select</option>
              { Object.entries(ContactConfigMailLevelEnum).map(([key,value]) => <option value={value}>{key}</option>) }
            </Form.Select>
            <Form.Text muted>
              メール通知の重要度レベル 設定した以上のレベルで通知されます（予定） (例：WARNに設定するとERRORとWARNが通知されINFOはされない)
            </Form.Text>
            { errors.data && errors.data.contactConfig && errors.data.contactConfig.mail && errors.data.contactConfig.mail.level
              && <span>Please provide a valid contactConfig.mail.level</span>
            }
          </Form.Group>
          <Form.Group className="mb-3" controlId="contactMailAddress">
            <Form.Label>contactMailAddress</Form.Label>
            <Form.Text muted>
              メール通知の宛先
            </Form.Text>
            <Form.Control { ...register('data.contactConfig.mail.mailAddress', { required: false }) } />
            { errors.data && errors.data.contactConfig && errors.data.contactConfig.mail && errors.data.contactConfig.mail.mailAddress
              && <span>Please provide a valid contactConfig.mail.mailAddress</span>
            }
          </Form.Group>


          <Button variant="primary" type="submit">Submit</Button>
        </Form>
      </LoginLayout>
    );
  } else {
    return <p>loading...</p>
  }
});