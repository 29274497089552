import { memo, useState, useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";

import { useGlobalConfigContext } from "../providers/GlobalConfig"
import {
  GoogleAuthorizationApi,

  GoogleAuthorizationCodeRequest,
  GoogleAuthorizationCodeResponse
} from "../openapi-generator/admin-api/";


export const SignInGoogleOAuth = memo(() => {

  const adminApiConfiguration = useGlobalConfigContext().adminApiConfiguration;
  const googleAuthorizationApi = new GoogleAuthorizationApi(adminApiConfiguration);

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [ response, setResponse ] = useState<GoogleAuthorizationCodeResponse>();

  useEffect(()=>{(async()=>{
    var [google] = await Promise.all([
      googleAuthorizationApi.googleAuthorizationCode({
        state: searchParams.get("state"),
        code: searchParams.get("code"),
        scope: searchParams.get("scope"),
        authuser: parseInt(searchParams.get("authuser") || "0"),
        prompt: searchParams.get("prompt")
      } as GoogleAuthorizationCodeRequest)
    ]);
    console.log(google.data);
    navigate("/");
  })();},[]);

  return (
    <>
      <p>OAuthページです</p>
      <ul>
        <li>state : {searchParams.get("state")}</li>
        <li>code : {searchParams.get("code")}</li>
        <li>scope : {searchParams.get("scope")}</li>
        <li>authuser : {searchParams.get("authuser")}</li>
        <li>prompt : {searchParams.get("prompt")}</li>
      </ul>
     </>
  );
});