import React from "react";
import { Configuration } from "../openapi-generator/admin-api/configuration";


export type GlobalConfigContextType = {
  adminApiConfiguration: Configuration | undefined;
}

const GlobalConfigContext = React.createContext<GlobalConfigContextType>({} as GlobalConfigContextType);

export const useGlobalConfigContext = ():GlobalConfigContextType => {
  return React.useContext<GlobalConfigContextType>(GlobalConfigContext);
}

type Props = {
  children: React.ReactNode
}

export const GlobalConfigProvider = (props:Props) => {
  const value:GlobalConfigContextType = {
    adminApiConfiguration: new Configuration({
      basePath: window.location.origin
    })
  };

  return (
    <GlobalConfigContext.Provider value={value}>
      {props.children}
    </GlobalConfigContext.Provider>
  );
}
