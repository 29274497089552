import { memo, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Stack from 'react-bootstrap/Stack';

import { useGlobalConfigContext } from "../providers/GlobalConfig"
import { LoginRequired, AlreadyLogin } from "../providers/LoginUser"
import { LoginLayout } from "../layout/LoginLayout"
import {
  BearerTokenApi,

  BearerTokenForList,
} from "../openapi-generator/admin-api/"

type PageParam = {
  bearerTokenForList: BearerTokenForList[];
}

export const SettingPage = memo(() => {

  const adminApiConfiguration = useGlobalConfigContext().adminApiConfiguration;
  const bearerTokenApi = new BearerTokenApi(adminApiConfiguration);

  const urlParams = useParams<{ projectId: string }>();
  const [param, setParam] = useState<PageParam>();

  useEffect(()=>{(async()=>{

    const [
      bearerTokenForList,
    ] = await Promise.all([
      bearerTokenApi.listBearerToken(),
    ]);

    setParam({
      bearerTokenForList: bearerTokenForList.data.results,
    });

  })();},[]);

  if (!!param) {

    return (
      <LoginLayout>

        <Stack direction="horizontal" gap={2}>
          <h3 className="p-2">Bearerトークン</h3>
          <div className="p-2"><a href={ `/setting/bearer-token-create` }>new</a></div>
        </Stack>
        <p>APIを呼び出すためのBearerトークンです。AuthorizationヘッダにつけてAPIを呼ぶことができます。</p>
        { param.bearerTokenForList.length > 0
          && <ul>{
             param.bearerTokenForList.map((item, index) =>
               <li>
                 <input type="text" value={ item.bearerTokenId } disabled />
                 <p>{item.description}</p>
               </li>
             )
          }</ul>
        }

      </LoginLayout>
    );
  } else {
    return <p>loading...</p>
  }
});