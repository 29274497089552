import { memo, useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { LoginRequired, AlreadyLogin } from "../providers/LoginUser"

import { useGlobalConfigContext } from "../providers/GlobalConfig"
import { LoginLayout } from "../layout/LoginLayout"
import {
  UserApi,

  ListUserResponse
} from "../openapi-generator/admin-api/";

type PageParam = {
  listUserResponse: ListUserResponse
}

export const UserListPage = memo(() => {

  const adminApiConfiguration = useGlobalConfigContext().adminApiConfiguration;
  const userApi = new UserApi(adminApiConfiguration);

  const [param, setParam] = useState<PageParam>();
  const search = useLocation().search;
  const query = new URLSearchParams(search);

  useEffect(()=>{(async()=>{

    const listUserResponse = await userApi.listUser(
      query.has("from") ? Number(query.get("from")) : undefined,
      query.has("size") ? Number(query.get("size")) : undefined
    );

    setParam({
      listUserResponse: listUserResponse.data
    });
  })();},[]);

  if (!!param) {

    return (
      <LoginLayout>
        <p>ユーザ一覧ページです</p>
        <p>登録されているユーザが一覧で表示されます</p>
        { param.listUserResponse.results.length > 0
          && <ul>{
             param.listUserResponse.results.map((item, index) =>
               <li>{item.userName}&nbsp;<a href={`/user/${encodeURIComponent(item.userId)}/edit`}>edit</a></li>
             )
          }</ul>
        }
      </LoginLayout>
    );
  } else {
    return <p>load....</p>
  }
});