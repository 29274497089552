import { memo, useState, useEffect, FormEvent } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm, SubmitHandler } from "react-hook-form";

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

import { useGlobalConfigContext } from "../providers/GlobalConfig"
import { LoginRequired, AlreadyLogin } from "../providers/LoginUser"
import { LoginLayout } from "../layout/LoginLayout"
import {
  ProjectApi,
  TicketCategoryApi,

  GetProjectResponse,
  GetTicketCategoryResponse,
  TicketCategoryForUpdate,
} from "../openapi-generator/admin-api/";

type PageParam = {
  getProjectResponse: GetProjectResponse;
}
type PageForm = {
  projectId: string;
  categoryName: string;
  data: TicketCategoryForUpdate;
  versionExpect: number;
}

export const TicketCategoryUpdatePage = memo(() => {

  const adminApiConfiguration = useGlobalConfigContext().adminApiConfiguration;
  const projectApi = new ProjectApi(adminApiConfiguration);
  const ticketCategoryApi = new TicketCategoryApi(adminApiConfiguration);

  const navigate = useNavigate();
  const [param, setParam] = useState<PageParam>();
  const urlParams = useParams<{ projectId: string, categoryName: string }>();
  const { handleSubmit, register, reset, formState: { errors, isValid, isSubmitting } }
   = useForm<PageForm>();

  const onSubmit: SubmitHandler<PageForm> = async (form) => {
    console.log(form);
    var response = await ticketCategoryApi.updateTicketCategory(form.projectId, form.categoryName, {
      ticketCategory: form.data,
      versionExpect: form.versionExpect
    });
    console.log(response);
    navigate("/project");
  };

  useEffect(()=>{(async()=>{

    const [
      getProjectResponse,
      getTicketCategoryResponse
    ] = await Promise.all([
      projectApi.getProject(urlParams.projectId || ""),
      ticketCategoryApi.getTicketCategory(urlParams.projectId || "", urlParams.categoryName || "")
    ]);

    reset({
      projectId: getProjectResponse.data.projectId,
      categoryName: getTicketCategoryResponse.data.categoryName,
      data: {
        description: getTicketCategoryResponse.data.description
      },
      versionExpect: getTicketCategoryResponse.data.version
    })

    setParam({
      getProjectResponse: getProjectResponse.data
    });

  })();},[reset]);

  if (!!param) {

    return (
      <LoginLayout>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Group className="mb-3" controlId="projectId">
            <Form.Label>プロジェクトID</Form.Label>
            <Form.Control { ...register('projectId', { required: true }) } disabled readOnly />
            { errors.projectId
              && <span>Please provide a valid projectId</span>
            }
          </Form.Group>
          <Form.Group className="mb-3" controlId="categoryName">
            <Form.Label>カテゴリ</Form.Label>
            <Form.Control { ...register('categoryName', { required: true }) } disabled readOnly />
            { errors.categoryName
              && <span>Please provide a valid categoryName</span>
            }
          </Form.Group>
          <Form.Group className="mb-3" controlId="description">
            <Form.Label>説明</Form.Label>
            <Form.Control { ...register('data.description', { required: false }) } />
            { errors.data && errors.data.description
              && <span>Please provide a valid description</span>
            }
          </Form.Group>


          <Button variant="primary" type="submit">Submit</Button>
        </Form>
      </LoginLayout>
    );
  } else {
    return <p>loading...</p>
  }
});