import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Router } from "./Router";
import { GlobalConfigProvider } from "./providers/GlobalConfig"
import { LoginUserProvider } from "./providers/LoginUser"


import reportWebVitals from './reportWebVitals';

// Importing the Bootstrap CSS
import 'bootstrap/dist/css/bootstrap.min.css';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
const onRender = (id:string, phase:string, actualDuration:number, baseDuration:number, startTime:any, commitTime:any) => {
  console.log(`id=${id}, phase=${phase}, actualDuration=${actualDuration}`);
}
root.render(
  <React.Profiler id="Sidebar" onRender={onRender}>
    <GlobalConfigProvider>
      <LoginUserProvider>
        <BrowserRouter>
          <Router />
        </BrowserRouter>
      </LoginUserProvider>
    </GlobalConfigProvider>
  </React.Profiler>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
