
import { memo, useState, useEffect } from "react";

import { useGlobalConfigContext } from "../providers/GlobalConfig"
import {
  GoogleAuthorizationApi

} from "../openapi-generator/admin-api/";

type PageParam = {
  googleUrl: string;
}
export const SignIn = () => {

  const adminApiConfiguration = useGlobalConfigContext().adminApiConfiguration;
  const googleAuthorizationApi = new GoogleAuthorizationApi(adminApiConfiguration);

  const [param, setParam] = useState<PageParam>()

  useEffect(()=>{(async()=>{
    var [google] = await Promise.all([
      googleAuthorizationApi.googleAuthorizationUrl()
    ]);

    setParam({
      googleUrl: google.data.href
    })
  })();},[]);

  if (!!param) {
    return <p>サインイン。<a href={param.googleUrl}>google</a></p>;
  } else {
    return <p>load....</p>
  }


};