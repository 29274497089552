import { memo, useState, useEffect, FormEvent } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm, SubmitHandler } from "react-hook-form";

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

import { useGlobalConfigContext } from "../providers/GlobalConfig";
import { LoginRequired, AlreadyLogin } from "../providers/LoginUser";
import { LoginLayout } from "../layout/LoginLayout";
import {
  BearerTokenApi,

  CreateBearerTokenRequest
} from "../openapi-generator/admin-api/";

type PageParam = {
}
type PageForm = {
  data: CreateBearerTokenRequest;
}

export const SettingBearerTokenCreatePage = memo(() => {

  const adminApiConfiguration = useGlobalConfigContext().adminApiConfiguration;
  const bearerTokenApi = new BearerTokenApi(adminApiConfiguration);

  const navigate = useNavigate();
  const urlParams = useParams<{ projectId: string }>();
  const [param, setParam] = useState<PageParam>();
  const { handleSubmit, register, reset, formState: { errors, isValid, isSubmitting } }
   = useForm<PageForm>();

  const onSubmit: SubmitHandler<PageForm> = async (form) => {
    console.log(form);
    var response = await bearerTokenApi.createBearerToken(form.data);
    console.log(response);
    navigate(`/setting`);
  };

  useEffect(()=>{(async()=>{

    reset({
      data: {
        description: ""
      }
    });

    setParam({
    });

  })();},[reset]);

  if (!!param) {

    return (
      <LoginLayout>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Group className="mb-3" controlId="description">
            <Form.Label>description</Form.Label>
            <Form.Control { ...register('data.description', { required: false }) } />
            { errors.data && errors.data.description
              && <span>Please provide a valid description</span>
            }
          </Form.Group>


          <Button variant="primary" type="submit">Submit</Button>
        </Form>
      </LoginLayout>
    );
  } else {
    return <p>loading...</p>
  }
});