import { memo, useState, useEffect, FormEvent } from "react";
import { useNavigate } from "react-router-dom";
import { useForm, SubmitHandler } from "react-hook-form";

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

import { useGlobalConfigContext } from "../providers/GlobalConfig"
import { LoginRequired, AlreadyLogin } from "../providers/LoginUser"
import { LoginLayout } from "../layout/LoginLayout"
import {
  TeamApi,

  TeamForUpdate,
  ListTeamResponse,
  EscalationConfigModeEnum,
  ContactConfigMailLevelEnum,
} from "../openapi-generator/admin-api/";

type PageParam = {
  listTeamResponse: ListTeamResponse;
}
type PageForm = {
  teamId: string;
  data: TeamForUpdate;
}

export const TeamCreatePage = memo(() => {

  const adminApiConfiguration = useGlobalConfigContext().adminApiConfiguration;
  const teamApi = new TeamApi(adminApiConfiguration);

  const navigate = useNavigate();
  const [param, setParam] = useState<PageParam>();
  const { handleSubmit, register, reset, formState: { errors, isValid, isSubmitting } }
   = useForm<PageForm>();

  const onSubmit: SubmitHandler<PageForm> = async (form) => {
    console.log(form);
    var response = await teamApi.createTeam(form.teamId, {
      team: form.data
    });
    console.log(response);
    navigate("/team");
  };

  useEffect(()=>{(async()=>{

    const listTeamResponse = await teamApi.listTeam(0, 1000);

    setParam({
      listTeamResponse: listTeamResponse.data
    });

  })();},[reset]);

  if (!!param) {

    return (
      <LoginLayout>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Group className="mb-3" controlId="teamId">
            <Form.Label>teamId</Form.Label>
            <Form.Control { ...register('teamId', { required: true }) } />
            { errors.teamId
              && <span>Please provide a valid teamId</span>
            }
          </Form.Group>
          <Form.Group className="mb-3" controlId="teamName">
            <Form.Label>teamName</Form.Label>
            <Form.Control { ...register('data.teamName', { required: true }) } />
            { errors.data && errors.data.teamName
              && <span>Please provide a valid teamName</span>
            }
          </Form.Group>
          <Form.Group className="mb-3" controlId="description">
            <Form.Label>description</Form.Label>
            <Form.Control { ...register('data.description', { required: false }) } />
            { errors.data && errors.data.description
              && <span>Please provide a valid description</span>
            }
          </Form.Group>
          <Form.Group className="mb-3" controlId="parentTeamId">
            <Form.Label>parentTeamId</Form.Label>
            <Form.Select { ...register('data.parentTeamId', { required: false }) } >
              <option value="">Default select</option>
              { param.listTeamResponse.results.map((item, _) => <option value={item.teamId}>{item.teamName}</option>) }
            </Form.Select>
            { errors.data && errors.data.parentTeamId
              && <span>Please provide a valid parentTeamId</span>
            }
          </Form.Group>


          <Form.Group className="mb-3" controlId="escalationMode">
            <Form.Label>mode</Form.Label>
            <Form.Select { ...register('data.escalationConfig.mode', { required: true }) }>
              <option value="">Default select</option>
              { Object.entries(EscalationConfigModeEnum).map(([key,value]) => <option value={value}>{key}</option>) }
            </Form.Select>
            { errors.data && errors.data.escalationConfig && errors.data.escalationConfig.mode
              && <span>Please provide a valid escalationConfig.mode</span>
            }
          </Form.Group>
          <Form.Group className="mb-3" controlId="escalationWaitSeconds">
            <Form.Label>escalationWaitSeconds</Form.Label>
            <Form.Control { ...register('data.escalationConfig.waitSeconds', { required: true }) } />
            { errors.data && errors.data.escalationConfig && errors.data.escalationConfig.waitSeconds
              && <span>Please provide a valid escalationConfig.waitSeconds</span>
            }
          </Form.Group>
          <Form.Group className="mb-3" controlId="escalationTryCount">
            <Form.Label>escalationTryCount</Form.Label>
            <Form.Control { ...register('data.escalationConfig.tryCount', { required: true }) } />
            { errors.data && errors.data.escalationConfig && errors.data.escalationConfig.tryCount
              && <span>Please provide a valid escalationConfig.tryCount</span>
            }
          </Form.Group>


          <Form.Group className="mb-3" controlId="contactLevel">
            <Form.Label>contactLevel</Form.Label>
            <Form.Select { ...register('data.contactConfig.mail.level', { required: false }) } >
              <option value="">Default select</option>
              { Object.entries(ContactConfigMailLevelEnum).map(([key,value]) => <option value={value}>{key}</option>) }
            </Form.Select>
            { errors.data && errors.data.contactConfig && errors.data.contactConfig.mail && errors.data.contactConfig.mail.level
              && <span>Please provide a valid contactConfig.mail.level</span>
            }
          </Form.Group>
          <Form.Group className="mb-3" controlId="contactMailAddress">
            <Form.Label>contactMailAddress</Form.Label>
            <Form.Control { ...register('data.contactConfig.mail.mailAddress', { required: false }) } />
            { errors.data && errors.data.contactConfig && errors.data.contactConfig.mail && errors.data.contactConfig.mail.mailAddress
              && <span>Please provide a valid contactConfig.mail.mailAddress</span>
            }
          </Form.Group>


          <Button variant="primary" type="submit">Submit</Button>
        </Form>
      </LoginLayout>
    );
  } else {
    return <p>loading...</p>
  }
});