import { memo, useState, useEffect, FormEvent } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm, SubmitHandler } from "react-hook-form";

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

import { useGlobalConfigContext } from "../providers/GlobalConfig";
import { LoginRequired, AlreadyLogin } from "../providers/LoginUser";
import { LoginLayout } from "../layout/LoginLayout";
import {
  ProjectApi,
  ProjectAwsSnsTokenApi,

  GetProjectResponse,
  CreateAwsSnsTokenRequest
} from "../openapi-generator/admin-api/";

type PageParam = {
  getProjectResponse: GetProjectResponse;
}
type PageForm = {
  projectId: string;
  data: CreateAwsSnsTokenRequest;
}

export const AwsSnsTokenCreatePage = memo(() => {

  const adminApiConfiguration = useGlobalConfigContext().adminApiConfiguration;
  const projectApi = new ProjectApi(adminApiConfiguration);
  const projectAwsSnsTokenApi = new ProjectAwsSnsTokenApi(adminApiConfiguration);

  const navigate = useNavigate();
  const urlParams = useParams<{ projectId: string }>();
  const [param, setParam] = useState<PageParam>();
  const { handleSubmit, register, reset, formState: { errors, isValid, isSubmitting } }
   = useForm<PageForm>();

  const onSubmit: SubmitHandler<PageForm> = async (form) => {
    console.log(form);
    var response = await projectAwsSnsTokenApi.createAwsSnsToken(form.projectId, form.data);
    console.log(response);
    navigate(`/project/${encodeURIComponent(form.projectId)}`);
  };

  useEffect(()=>{(async()=>{

    const getProjectResponse = await projectApi.getProject(urlParams.projectId || "");

    reset({
      projectId: getProjectResponse.data.projectId,
      data: {
        description: ""
      }
    });

    setParam({
      getProjectResponse: getProjectResponse.data
    });

  })();},[reset]);

  if (!!param) {

    return (
      <LoginLayout>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Group className="mb-3" controlId="projectId">
            <Form.Label>projectId</Form.Label>
            <Form.Control { ...register('projectId', { required: true }) } disabled readOnly />
            { errors.projectId
              && <span>Please provide a valid projectId</span>
            }
          </Form.Group>
          <Form.Group className="mb-3" controlId="description">
            <Form.Label>description</Form.Label>
            <Form.Control { ...register('data.description', { required: false }) } />
            { errors.data && errors.data.description
              && <span>Please provide a valid description</span>
            }
          </Form.Group>


          <Button variant="primary" type="submit">Submit</Button>
        </Form>
      </LoginLayout>
    );
  } else {
    return <p>loading...</p>
  }
});