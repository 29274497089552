import { memo, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Stack from 'react-bootstrap/Stack';

import { useGlobalConfigContext } from "../providers/GlobalConfig"
import { LoginRequired, AlreadyLogin } from "../providers/LoginUser"
import { LoginLayout } from "../layout/LoginLayout"
import {
  ProjectApi,
  TicketCategoryApi,
  ProjectAwsSnsTokenApi,
  ProjectAwsSesTokenApi,

  GetProjectResponse,
  TicketCategoryForList,
  AwsSnsTokenForList,
  AwsSesTokenForList,
} from "../openapi-generator/admin-api/"

type PageParam = {
  getProjectResponse: GetProjectResponse;
  ticketCategoryForList: TicketCategoryForList[];
  awsSnsTokenForList: AwsSnsTokenForList[];
  awsSesTokenForList: AwsSesTokenForList[];
}

export const ProjectDetailPage = memo(() => {

  const adminApiConfiguration = useGlobalConfigContext().adminApiConfiguration;
  const projectApi = new ProjectApi(adminApiConfiguration);
  const ticketCategoryApi = new TicketCategoryApi(adminApiConfiguration);
  const projectAwsSnsTokenApi = new ProjectAwsSnsTokenApi(adminApiConfiguration);
  const projectAwsSesTokenApi = new ProjectAwsSesTokenApi(adminApiConfiguration);

  const urlParams = useParams<{ projectId: string }>();
  const [param, setParam] = useState<PageParam>();

  useEffect(()=>{(async()=>{

    const [
      getProjectResponse,
      listTicketCategoryResponse,
      listAwsSnsTokenResponse,
      listAwsSesTokenResponse,
    ] = await Promise.all([
      projectApi.getProject(urlParams.projectId || ""),
      ticketCategoryApi.listTicketCategory(urlParams.projectId || ""),
      projectAwsSnsTokenApi.listAwsSnsToken(urlParams.projectId || ""),
      projectAwsSesTokenApi.listAwsSesToken(urlParams.projectId || ""),
    ]);

    setParam({
      getProjectResponse: getProjectResponse.data,
      ticketCategoryForList: listTicketCategoryResponse.data.results,
      awsSnsTokenForList: listAwsSnsTokenResponse.data.results,
      awsSesTokenForList: listAwsSesTokenResponse.data.results,
    });

  })();},[]);

  if (!!param) {

    return (
      <LoginLayout>
        <h2 className="p-2">{param.getProjectResponse.projectName} (id:{param.getProjectResponse.projectId})</h2>
        <Stack direction="horizontal" gap={2}>
          <h3 className="p-2">基本情報</h3>
          <div className="p-2"><a href={ `/project/${encodeURIComponent(param.getProjectResponse.projectId)}/edit` }>edit</a></div>
          <div className="p-2"><a href={ `/ticket/${encodeURIComponent(param.getProjectResponse.projectId)}/create` }>ticket</a></div>
        </Stack>
        <dl>
          <dt>description</dt>
          <dd>{param.getProjectResponse.description}</dd>

          <dt>teamName</dt>
          <dd>{param.getProjectResponse.team.teamName}</dd>
        </dl>

        <Stack direction="horizontal" gap={2}>
          <h3 className="p-2">AWS SNSインテグレーション</h3>
          <div className="p-2"><a href={ `/project/${encodeURIComponent(param.getProjectResponse.projectId)}/aws-sns-integration-create` }>new</a></div>
        </Stack>
        <p>SNSのサブスクリプションに登録することにより自動的にこのプロジェクト宛にチケットを作成できます。現在対応しているのはCloudWatchAlarm、Budgets、PersonalHealthDashboardの3種類です。（順次追加予定 </p>
        { param.awsSnsTokenForList.length > 0
          && <ul>{
             param.awsSnsTokenForList.map((item, index) =>
               <li>
                 <input type="text" value={ item.endpoint } disabled />
                 <p>{item.description}</p>
               </li>
             )
          }</ul>
        }

        <Stack direction="horizontal" gap={2}>
          <h3 className="p-2">AWS SESインテグレーション</h3>
          <div className="p-2"><a href={ `/project/${encodeURIComponent(param.getProjectResponse.projectId)}/aws-ses-integration-create` }>new</a></div>
        </Stack>
        <p>（工事中）メールによるチケットの自動登録をできるようにする予定です</p>
        { param.awsSesTokenForList.length > 0
          && <ul>{
             param.awsSesTokenForList.map((item, index) =>
               <li>
                 <input type="text" value={ `${item.awsSesTokenId}@team-lab.com` } disabled />
                 <p>{item.ticketMode}</p>
                 <p>{item.description}</p>
               </li>
             )
          }</ul>
        }


        <Stack direction="horizontal" gap={2}>
          <h3 className="p-2">カテゴリ</h3>
          <div className="p-2"><a href={ `/ticket-category/${encodeURIComponent(param.getProjectResponse.projectId)}/create` }>new</a></div>
        </Stack>
        <p>チケットを分類するためのカテゴリを作成できます。カテゴリには対処方法などの説明を登録することができます。重要度の設定や自動アサインのための設定も今後追加予定です。</p>
        { param.ticketCategoryForList.length > 0
          && <ul>{
             param.ticketCategoryForList.map((item, index) =>
               <li><a href={`/ticket-category/${encodeURIComponent(item.project.projectId)}/${encodeURIComponent(item.categoryName)}`}>{item.categoryName}</a>&nbsp;<a href={`/ticket-category/${encodeURIComponent(item.project.projectId)}/${encodeURIComponent(item.categoryName)}/edit`}>edit</a></li>
             )
          }</ul>
        }

      </LoginLayout>
    );
  } else {
    return <p>loading...</p>
  }
});