import React from "react";
import { Navigate } from "react-router-dom";
import { useGlobalConfigContext } from "../providers/GlobalConfig"
import { UserApi } from "../openapi-generator/admin-api/";


export type LoginUser = {
  isLogin: boolean;
  id: string | null;
  name: string;
}
export type LoginUserContextType = {
  user: LoginUser | undefined | null;
  signIn: (user:LoginUser | null) => void;
  signOut: () => void;
}

const LoginUserContext = React.createContext<LoginUserContextType>({} as LoginUserContextType);

export const useLoginUserContext = ():LoginUserContextType => {
  return React.useContext<LoginUserContextType>(LoginUserContext);
}

type Props = {
  children: React.ReactNode
}

export const LoginUserProvider = (props:Props) => {

  const [user, setUser] = React.useState<LoginUser|null>();

  const value:LoginUserContextType = {
    user: user,
    signIn: (newUser: LoginUser | null) => { setUser(newUser); },
    signOut: () => {}
  };

  return (
    <LoginUserContext.Provider value={value}>
      <Loading>{props.children}</Loading>
    </LoginUserContext.Provider>
  );
}

type LoadingProps = {
  children: React.ReactNode;
}

const Loading: React.VFC<LoadingProps> = (props) => {

  const adminApiConfiguration = useGlobalConfigContext().adminApiConfiguration;
  const userApi = new UserApi(adminApiConfiguration);

  const context = useLoginUserContext();

  React.useEffect(() => {
    (async() => {
      const response = await userApi.whoAmI();
      if (!!response.data.myInfo) {
        context.signIn({
          isLogin: true,
          id : response.data.myInfo.userId,
          name : response.data.myInfo.userName
        } as LoginUser);
      } else {
        context.signIn({
          isLogin: false,
          id : null,
          name : "Guest"
        } as LoginUser);
      }
    })();
  }, []);

  if (!!context.user) {
    return <>{props.children}</>;
  }

  return <p>Loading...</p>
}

type LoginRequiredProps = {
  children: React.ReactNode;
  redirect: string,
}

export const LoginRequired: React.VFC<LoginRequiredProps> = (props) => {
  const user = useLoginUserContext().user;
  if (!!user && !user.isLogin) {
    return <Navigate to={props.redirect} replace={true} />
  }

  return <>{props.children}</>;
}

export const AlreadyLogin: React.VFC<LoginRequiredProps> = (props) => {
  const user = useLoginUserContext().user;

  if (!!user && user.isLogin) {
    return <Navigate to={props.redirect} replace={true} />
  }

  return <>{props.children}</>;
}