import { memo, useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";

import { useGlobalConfigContext } from "../providers/GlobalConfig"
import { LoginRequired, AlreadyLogin } from "../providers/LoginUser"
import { LoginLayout } from "../layout/LoginLayout"
import {
  ProjectApi,

  ListProjectResponse
} from "../openapi-generator/admin-api/";

type PageParam = {
  listProjectResponse: ListProjectResponse;
}

export const ProjectListPage = memo(() => {

  const adminApiConfiguration = useGlobalConfigContext().adminApiConfiguration;
  const projectApi = new ProjectApi(adminApiConfiguration);

  const navigate = useNavigate();
  const [param, setParam] = useState<PageParam>()
  const search = useLocation().search;
  const query = new URLSearchParams(search);

  useEffect(()=>{(async()=>{

    const listProjectResponse = await projectApi.listProject(
      query.has("from") ? Number(query.get("from")) : undefined,
      query.has("size") ? Number(query.get("size")) : undefined
    );

    setParam({
      listProjectResponse: listProjectResponse.data
    });
  })();},[]);

  if (!!param) {

    return (
      <LoginLayout>
        <p>Projectページです&nbsp;<Link to="/project-create">New</Link></p>
        { param.listProjectResponse.results.length > 0
          && <ul>{
             param.listProjectResponse.results.map((item, index) =>
               <li><a href={`/project/${encodeURIComponent(item.projectId)}`}>{item.projectName}</a>&nbsp;<a href={`/project/${encodeURIComponent(item.projectId)}/edit`}>edit</a></li>
             )
          }</ul>
        }

      </LoginLayout>
    );
  } else {
    return <p>load....</p>
  }
});