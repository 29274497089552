import { Route, Routes, useNavigate, Navigate } from "react-router-dom";
import { Header } from "./components/Header";
import { FailedLogin } from "./pages/FailedLogin";
import { Home } from "./pages/Home";
import { SignIn } from "./pages/SignIn";
import { SignInGoogleOAuth } from "./pages/SignInGoogleOAuth";
import { LoginRequired, AlreadyLogin } from "./providers/LoginUser"

import { Error404Page } from "./pages/Error404Page"

import { TeamListPage } from "./pages/TeamListPage"
import { TeamDetailPage } from "./pages/TeamDetailPage"
import { TeamCreatePage } from "./pages/TeamCreatePage"
import { TeamEditPage } from "./pages/TeamEditPage"
import { TeamMemberPage } from "./pages/TeamMemberPage"

import { UserListPage } from "./pages/UserListPage"
import { UserEditPage } from "./pages/UserEditPage"

import { ProjectListPage } from "./pages/ProjectListPage"
import { ProjectCreatePage } from "./pages/ProjectCreatePage"
import { ProjectDetailPage } from "./pages/ProjectDetailPage"
import { ProjectEditPage } from "./pages/ProjectEditPage"

import { AwsSnsTokenCreatePage } from "./pages/AwsSnsTokenCreatePage"
import { AwsSesTokenCreatePage } from "./pages/AwsSesTokenCreatePage"

import { TicketCategoryCreatePage } from "./pages/TicketCategoryCreatePage"
import { TicketCategoryUpdatePage } from "./pages/TicketCategoryUpdatePage"

import { TicketListPage } from "./pages/TicketListPage"
import { TicketCreatePage } from "./pages/TicketCreatePage"
import { TicketUpdatePage } from "./pages/TicketUpdatePage"
import { TicketDetailPage } from "./pages/TicketDetailPage"

import { SettingPage } from "./pages/SettingPage"
import { SettingBearerTokenCreatePage } from "./pages/SettingBearerTokenCreatePage"


export const Router = () => {
  return (
    <Routes>
      <Route path="/sign-in" element={<AlreadyLogin redirect="/home"><Header /><SignIn /></AlreadyLogin>}/>
      <Route path="/sign-in/google-oauth" element={<SignInGoogleOAuth />} />
      <Route path="/"  element={<LoginRequired redirect="/sign-in"><Home /></LoginRequired>} />

      <Route path="/user" element={<LoginRequired redirect="/sign-in"><UserListPage /></LoginRequired>} />
      <Route path="/user/:userId/edit" element={<LoginRequired redirect="/sign-in"><UserEditPage /></LoginRequired>} />

      <Route path="/team" element={<LoginRequired redirect="/sign-in"><TeamListPage /></LoginRequired>} />
      <Route path="/team-create" element={<LoginRequired redirect="/sign-in"><TeamCreatePage /></LoginRequired>} />
      <Route path="/team/:teamId" element={<LoginRequired redirect="/sign-in"><TeamDetailPage /></LoginRequired>} />
      <Route path="/team/:teamId/edit" element={<LoginRequired redirect="/sign-in"><TeamEditPage /></LoginRequired>} />
      <Route path="/team/:teamId/member" element={<LoginRequired redirect="/sign-in"><TeamMemberPage /></LoginRequired>} />

      <Route path="/project" element={<LoginRequired redirect="/sign-in"><ProjectListPage /></LoginRequired>} />
      <Route path="/project-create" element={<LoginRequired redirect="/sign-in"><ProjectCreatePage /></LoginRequired>} />
      <Route path="/project/:projectId" element={<LoginRequired redirect="/sign-in"><ProjectDetailPage /></LoginRequired>} />
      <Route path="/project/:projectId/edit" element={<LoginRequired redirect="/sign-in"><ProjectEditPage /></LoginRequired>} />

      <Route path="/ticket-category/:projectId/create" element={<LoginRequired redirect="/sign-in"><TicketCategoryCreatePage /></LoginRequired>} />
      <Route path="/ticket-category/:projectId/:categoryName/edit" element={<LoginRequired redirect="/sign-in"><TicketCategoryUpdatePage /></LoginRequired>} />

      <Route path="/project/:projectId/aws-sns-integration-create" element={<LoginRequired redirect="/sign-in"><AwsSnsTokenCreatePage /></LoginRequired>} />
      <Route path="/project/:projectId/aws-ses-integration-create" element={<LoginRequired redirect="/sign-in"><AwsSesTokenCreatePage /></LoginRequired>} />

      <Route path="/ticket" element={<LoginRequired redirect="/sign-in"><TicketListPage /></LoginRequired>} />
      <Route path="/ticket/:projectId/create" element={<LoginRequired redirect="/sign-in"><TicketCreatePage /></LoginRequired>} />
      <Route path="/ticket/:projectId/:ticketNumber" element={<LoginRequired redirect="/sign-in"><TicketDetailPage /></LoginRequired>} />
      <Route path="/ticket/:projectId/:ticketNumber/edit" element={<LoginRequired redirect="/sign-in"><TicketUpdatePage /></LoginRequired>} />

      <Route path="/setting" element={<LoginRequired redirect="/sign-in"><SettingPage /></LoginRequired>} />
      <Route path="/setting/bearer-token-create" element={<LoginRequired redirect="/sign-in"><SettingBearerTokenCreatePage /></LoginRequired>} />

      <Route path="/fail-login" element={<FailedLogin />} />
      <Route path="*" element={<Error404Page/>} />
    </Routes>
  );
};